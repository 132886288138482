import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import AuthForm from './forms/AuthForm'
import {connect} from 'react-redux'
import {
    signIn
} from '../actions'
import {
    Redirect
} from 'react-router-dom'
import Helmet from 'react-helmet'

class SignIn extends React.Component {

    onSubmit = formValues => {
        this.props.signIn(formValues)
    }

    render(){
        if(this.props.auth.token){
            return <Redirect to="/dashboard" />
        }

        return(
            <Layout>
                <Helmet>
                    <title>Admin Sign In | Cecil Vinegar Works</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                 <ScrollToTop />
                <section className='_section _section-page-heading-normal'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_section-heading'>SIGN IN</h1>
                        </div>
                    </div>
                </section>
                <section className='_section _section-signin'>
                    <div className='_grid _form-grid-small'>
                        <div className='_column'>
                            <AuthForm 
                                onSubmit={this.onSubmit} 
                                errors={this.props.auth.errors ? this.props.auth.errors : null} 
                            />
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return{
        auth: state.authState
    }
}

export default connect(mapStateToProps, {
    signIn
})(SignIn)