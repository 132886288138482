import _ from 'lodash'
import {
    FETCH_PUBLIC_PAGE_RECIPES
} from '../actions/types'

const initialState = {}

const publicRecipesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PAGE_RECIPES:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        default:
            return state
    }
}

export default publicRecipesReducer