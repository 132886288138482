import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchPublicPageBrandsMenu
} from '../../actions'

class NavLeft extends React.Component{

    componentDidMount(){
        this.props.fetchPublicPageBrandsMenu()
    }

    renderBrandLinks = () => {
        return this.props.brandLinks.map(({name,slug, link}, index) => {
            return (
                <li key={index}>
                    {
                        !link && (
                            <Link to={`/products/${slug}`}>{name}</Link>
                        )
                    }
                    {
                        link && (
                            <a href={link} target="_blank" rel="noreferrer">{name}</a>
                        )
                    }
                </li>
            )
        })
    }

    render(){

        return(
            <ul>
                <li>
                    <Link to="/">HOME</Link>
                </li>
                <li>
                    <Link to="/products">PRODUCTS</Link>
                    <ul>
                        {this.renderBrandLinks()}
                    </ul>
                </li>
            </ul>
        )
    }
}

const mapStateToProps = state => {
    return {
        brandLinks: state.publicBrandsMenuState
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageBrandsMenu
})(NavLeft)