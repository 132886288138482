import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from './fields'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Recaptcha from 'react-recaptcha'

class ContactForm extends React.Component {

    state= {
        button: 'Send',
        message: '',
        capchaLoaded: null,
        capchaVerified: null
    }

    callback = () => {
        console.log('recapcha has loaded')
        this.setState({
            capchaLoaded: true
        })
    }

    verifyCallback = response => {
        console.log('recapcha has been verified')
        this.setState({
            capchaVerified: true
        })
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        if(this.state.capchaLoaded){
            if(this.state.capchaVerified){
                this.setState({
                    button: 'Sending'
                })
                const data = new FormData()
                formValues.full_name && data.append('full_name', formValues.full_name)
                formValues.email && data.append('email', formValues.email)
                this.state.message && data.append('message', this.state.message)
                this.props.onSubmit(data)

            } else {
                console.log('Robot filter denied')
            }
        }
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="full_name" type="text" component={Input} label="Full Name*" />
                    <Field name="email" type="email" component={Input} label="Email*" />
                </div>



                <div className="_form-row">
                    <div className="_form-group">
                        <label>How can we help you?*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.message}
                            onChange={ ( e, editor ) => {
                                const message = editor.getData();
                                this.setState({ message })
                            } }
                        />
                    </div>
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Please verify that you are not a robot*</label>
                        <Recaptcha
                            sitekey="6Le7o4kfAAAAABfOtsy1XOZNv1pwiWAx49Vf4ctF"
                            render="explicit"
                            onloadCallback={this.callback}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>
                </div>

                <div className="_form-group">
                    <button className={`_button _button-2 _button-submit ${this.state.button === 'Sending' ? '_sending' : ''}`}>Send</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }

    if(!formValues.full_name){
        errors.full_name = "You must enter your full name"
    }

    if(!formValues.message){
        errors.message = "You must enter your message"
    }

    return errors
}

export default reduxForm({
    form: 'contact',
    validate
})(ContactForm)