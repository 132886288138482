import React from 'react'
import Slider from "react-slick";
import {BASE_PATH} from '../../paths'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {Link} from 'react-router-dom'

class CollectionSection extends React.Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    renderItems = () => {
        return this.props.data.products.map(({image, name, slug, brandSlug}, index) => {
            return (
                <Link to={`/products/${brandSlug}/${slug}`} className='_item' key={index}>
                    <img className='_product-image-related' src={BASE_PATH+image} alt="" />
                    <h3>{name}</h3>
                    <button className='_button _button-2'>View Product</button>
                </Link>
            )
        })
    }

    render(){

        if(!this.props.data.products.length > 0){return <></>}

        const settings = {
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            easing: 'ease-in-out',
            pauseOnHover: false,
            dots: true,
            infinite: this.props.data.products.length > 3 ? true : false,
            responsive: [
                {
                    breakpoint: 1023,
                    settings:{
                        slidesToShow: 3,
                        infinite: this.props.data.products.length > 2 ? true : false,
                    }
                },
                {
                    breakpoint: 767,
                    settings:{
                        slidesToShow: 1,
                        infinite: true,
                    }
                },
            ]
        };

        return(
            <section className='_section _section-collection'>
                <div className='_grid'>
                    <div className='_column'>
                        <h2 className='_section-heading'>{this.props.data.name && this.props.data.name}</h2>
                    </div>
                </div>
                <div className='_grid'>
                    <div className='_column'>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                            {this.props.data.products && this.renderItems()}
                        </ Slider>
                    </div>
                </div>
                <div className='_background-block' />
                <div className='_arrow _arrow-previous'>
                    <button onClick={this.previous} />
                </div>
                <div className='_arrow _arrow-next'>
                    <button onClick={this.next} />
                </div>
            </section>
        )
    }
}

export default CollectionSection