import _ from 'lodash'
import {
    FETCH_BRANDS_BIN,
    RESTORE_BRAND,
    FORCE_DELETE_BRAND
} from '../actions/types'

const initialState = {}

const brandsBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_BRANDS_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_BRAND:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_BRAND:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default brandsBinReducer