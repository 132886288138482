import _ from 'lodash'
import {
    FETCH_RANGES,
    CREATE_RANGE,
    FETCH_RANGE,
    UPDATE_RANGE,
    DELETE_RANGE,
    RESTORE_RANGE
} from '../actions/types'

const initialState = {}

const rangesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_RANGES:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_RANGE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_RANGE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_RANGE:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_RANGE:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_RANGE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default rangesReducer