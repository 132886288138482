import React from 'react'

class PageHeaderSection extends React.Component {
    render(){

        const {img} = this.props

        return(
            <section className='_section _section-page-heading' style={{
                backgroundImage: `url(${img})`
            }}>
                <div className='_overlay' />
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_panel'>
                            {this.props.children}
                            <div className='_vertical-line' />
                        </div>
                    </div>
                </div>
            </section>
            
        )
    }
}

export default PageHeaderSection