import React from 'react'
import {BASE_PATH} from '../../paths'

class RecipesSection extends React.Component {

    renderRecipes = () => {
        return this.props.data.map(({name, image, summary, link}, index)=>{
            return (
                <div className='_column' key={index}>
                    <img src={BASE_PATH+image} alt={name} />
                    <h3>{name}</h3>
                    <div dangerouslySetInnerHTML={{__html: summary}} />
                    <a href={link} className="_button _button-2 _recipe-button" target="_blank" rel="noreferrer">View Recipe</a>
                </div>
            )
        })
    }

    render(){
        return(
            <section className={`_section _section-recipes ${this.props.className ? this.props.className : ''}`}>
                {
                    this.props.children && (
                        <div className='_grid'>
                            <div className='_column'>
                                <div className='_panel'>{this.props.children}</div>
                            </div>
                        </div>
                    )
                }
                
                <div className='_grid _grid-3'>
                    {this.renderRecipes()}
                </div>
            </section>
        )
    }
}

export default RecipesSection