import admin from '../apis/admin'

import {
    SIGNIN,
    SIGNIN_FAILED,
    SIGNOUT,
    RESET_FORMS,
    FETCH_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_FAILES,
    FETCH_USERS,
    CREATE_USER,
    CREATE_USER_FAILED,
    FETCH_USER,
    UPDATE_USER,
    UPDATE_USER_FAILED,
    DELETE_USER,
    FETCH_BRANDS,
    CREATE_BRAND,
    CREATE_BRAND_FAILED,
    FETCH_BRAND,
    UPDATE_BRAND,
    UPDATE_BRAND_FAILED,
    DELETE_BRAND,
    RESTORE_BRAND,
    FETCH_BRANDS_BIN,
    FORCE_DELETE_BRAND,
    FETCH_RANGES,
    CREATE_RANGE,
    CREATE_RANGE_FAILED,
    FETCH_RANGE,
    UPDATE_RANGE,
    UPDATE_RANGE_FAILED,
    DELETE_RANGE,
    RESTORE_RANGE,
    FETCH_RANGES_BIN,
    FORCE_DELETE_RANGE,
    FETCH_PRODUCTS,
    CREATE_PRODUCT,
    CREATE_PRODUCT_FAILED,
    FETCH_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_FAILED,
    DELETE_PRODUCT,
    RESTORE_PRODUCT,
    FETCH_PRODUCTS_BIN,
    FORCE_DELETE_PRODUCT,
    FETCH_RECIPES,
    CREATE_RECIPE,
    CREATE_RECIPE_FAILED,
    FETCH_RECIPE,
    UPDATE_RECIPE,
    UPDATE_RECIPE_FAILED,
    DELETE_RECIPE,
    RESTORE_RECIPE,
    FETCH_RECIPES_BIN,
    FORCE_DELETE_RECIPE,
    FETCH_PUBLIC_PAGE_BRANDS,
    FETCH_PUBLIC_PAGE_BRANDS_MENU,
    FETCH_PUBLIC_PAGE_LOGOS,
    FETCH_PUBLIC_PAGE_BRANDS_EXPLORE,
    FETCH_PUBLIC_PAGE_BRAND_BANNER,
    FETCH_PUBLIC_PAGE_BRAND_RANGES,
    FETCH_PUBLIC_PAGE_PRODUCT,
    FETCH_PUBLIC_PAGE_RECIPES,
    SEND_CONTACT_MESSAGE,
    SEND_CONTACT_MESSAGE_FAILED
} from './types'

export const resetForms = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FORMS
        })
    }
}

export const sendContactMessage = formValues => async dispatch => {
    try {

        const response = await admin.post('contact', formValues)

        dispatch({
            type: SEND_CONTACT_MESSAGE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SEND_CONTACT_MESSAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signIn = formValues => async dispatch => {
    try {

        const response = await admin.post('login', formValues)

        dispatch({
            type: SIGNIN,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SIGNIN_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signOut = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/logout', {}, config)

        console.log('signed out')

        dispatch({
            type: SIGNOUT
        })
        
    } catch (error) {

        console.log('sign out failed')
        
        dispatch({
            type: SIGNOUT
        })
    }
}

export const fetchAccountDetails = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/my-account', config)

        dispatch({
            type: FETCH_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch account details failed')
    }
}

export const updateAccountDetails = (token, formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/my-account/update', formValues, config)

        dispatch({
            type: UPDATE_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_ACCOUNT_DETAILS_FAILES,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUsers = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/users', config)

        dispatch({
            type: FETCH_USERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching users failed')
    }
}

export const createUser = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/users/create', formValues, config)

        dispatch({
            type: CREATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/users/'+id, config)

        dispatch({
            type: FETCH_USER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching user failed')
    }
}


export const updateUser = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/users/'+id+'/update', formValues, config)

        dispatch({
            type: UPDATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const deleteUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/users/'+id+'/delete', {}, config)

        dispatch({
            type: DELETE_USER,
            payload: id
        })
        
    } catch (error) {
        console.log('delete user failed')
    }
}

//brands

export const fetchBrands = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/brands', config)

        dispatch({
            type: FETCH_BRANDS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch brands failed')
    }
}

export const createBrand = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/brands/create', formValues, config)

        dispatch({
            type: CREATE_BRAND,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_BRAND_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchBrand = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/brands/' + id, config)

        dispatch({
            type: FETCH_BRAND,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch brand failed')
    }
}

export const updateBrand = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/brands/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_BRAND,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_BRAND_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteBrand = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/brands/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_BRAND,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete brand failed')        
    }
}

export const fetchBrandsBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/brands/bin', config)

        dispatch({
            type: FETCH_BRANDS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch brands bin failed')
    }
}

export const restoreBrand = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/brands/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_BRAND,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Brand restore failed')
    }
}

export const forceDeleteBrand = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/brands/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_BRAND,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete brand failed')        
    }
}

//ranges

export const fetchRanges = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/ranges', config)

        dispatch({
            type: FETCH_RANGES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch ranges failed')
    }
}

export const createRange = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/ranges/create', formValues, config)

        dispatch({
            type: CREATE_RANGE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_RANGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchRange = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/ranges/' + id, config)

        dispatch({
            type: FETCH_RANGE,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch range failed')
    }
}

export const updateRange = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/ranges/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_RANGE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_RANGE_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteRange = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/ranges/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_RANGE,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete range failed')        
    }
}

export const fetchRangesBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/ranges/bin', config)

        dispatch({
            type: FETCH_RANGES_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch ranges bin failed')
    }
}

export const restoreRange = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/ranges/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_RANGE,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Range restore failed')
    }
}

export const forceDeleteRange = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/ranges/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_RANGE,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete range failed')        
    }
}

//products

export const fetchProducts = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/products', config)

        dispatch({
            type: FETCH_PRODUCTS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch products failed')
    }
}

export const createProduct = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/create', formValues, config)

        dispatch({
            type: CREATE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_PRODUCT_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/products/' + id, config)

        dispatch({
            type: FETCH_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch product failed')
    }
}

export const updateProduct = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/products/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_PRODUCT,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete product failed')        
    }
}

export const fetchProductsBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/products/bin', config)

        dispatch({
            type: FETCH_PRODUCTS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch products bin failed')
    }
}

export const restoreProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Product restore failed')
    }
}

export const forceDeleteProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/products/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_PRODUCT,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete product failed')        
    }
}

//recipes

export const fetchRecipes = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/recipes', config)

        dispatch({
            type: FETCH_RECIPES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch recipes failed')
    }
}

export const createRecipe = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/recipes/create', formValues, config)

        dispatch({
            type: CREATE_RECIPE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_RECIPE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchRecipe = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/recipes/' + id, config)

        dispatch({
            type: FETCH_RECIPE,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch recipe failed')
    }
}

export const updateRecipe = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/recipes/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_RECIPE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_RECIPE_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteRecipe = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/recipes/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_RECIPE,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete recipe failed')        
    }
}

export const fetchRecipesBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/recipes/bin', config)

        dispatch({
            type: FETCH_RECIPES_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch recipes bin failed')
    }
}

export const restoreRecipe = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/recipes/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_RECIPE,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Recipe restore failed')
    }
}

export const forceDeleteRecipe = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/recipes/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_RECIPE,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete recipe failed')        
    }
}

//public pages

export const fetchPublicPageBrands = () => async dispatch => {
    try {

        const response = await admin.get('brands')

        dispatch({
            type: FETCH_PUBLIC_PAGE_BRANDS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch brands failed')
    }
}

export const fetchPublicPageBrandsMenu = () => async dispatch => {
    try {

        const response = await admin.get('brands/menu')

        dispatch({
            type: FETCH_PUBLIC_PAGE_BRANDS_MENU,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch brand menu items failed')
    }
}

export const fetchPublicPageLogos = () => async dispatch => {
    try {

        const response = await admin.get('brands/logos')

        dispatch({
            type: FETCH_PUBLIC_PAGE_LOGOS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch logos failed')
    }
}

export const fetchPublicPageExplore = brandSlug => async dispatch => {
    try {

        const response = await admin.get(`brands/${brandSlug}/explore`)

        dispatch({
            type: FETCH_PUBLIC_PAGE_BRANDS_EXPLORE,
            payload: response.data,
            brandSlug
        })
        
    } catch (error) {
        console.log('fetch explore failed')
    }
}

export const fetchPublicPageBrandBanner = brandSlug => async dispatch => {
    try {

        const response = await admin.get(`brands/${brandSlug}/banner`)

        dispatch({
            type: FETCH_PUBLIC_PAGE_BRAND_BANNER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch banner failed')
    }
}

export const fetchPublicPageRanges = brandSlug => async dispatch => {
    try {

        const response = await admin.get(`brands/${brandSlug}/ranges`)

        dispatch({
            type: FETCH_PUBLIC_PAGE_BRAND_RANGES,
            payload: response.data,
            brandSlug
        })
        
    } catch (error) {
        console.log('fetch ranges failed')
    }
}

export const fetchPublicPageProduct = productSlug => async dispatch => {
    try {

        const response = await admin.get(`products/${productSlug}`)

        dispatch({
            type: FETCH_PUBLIC_PAGE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch product failed')
    }
}

export const fetchPublicPageRecipes = () => async dispatch => {
    try {

        const response = await admin.get('recipes')

        dispatch({
            type: FETCH_PUBLIC_PAGE_RECIPES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch recipes failed')
    }
}