import React from 'react'

class TimelineItem extends React.Component {

    state = {
        open: true
    }

    onToggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render(){

        const {year, heading, description, image} = this.props.data

        return(
            <div className={`_item ${this.state.open ? '_open' : ''}`}>
                <div className='_year'><span>{year}</span></div>
                <div className='_grid _grid-2'>
                    <div className='_column _left'>
                        <div className='_panel'>
                            {image && (
                                <>
                                    <div className='_year-background'>{year}</div>
                                    <div><img src={image} alt="" /></div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='_column _right'>
                        <div className='_panel'>
                            <h2 className='_section-heading'>{heading}</h2>
                            <div className='_copy'>
                                {description}
                            </div>
                            {/* <button className={`_readmore ${!this.state.open ? '' : '_up'}`} onClick={this.onToggle}>{!this.state.open ? 'Read More' : 'Read Less'}</button> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TimelineItem