import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/exports/exports-banner.jpg'
import botswana from '../assets/img/exports/botswana@2x.png'
// import israel from '../assets/img/exports/israel@2x.png'
import mozambique from '../assets/img/exports/mozambique@2x.png'
import namibia from '../assets/img/exports/namibia@2x.png'
import newzealand from '../assets/img/exports/new-zealand@2x.png'
import swaziland from '../assets/img/exports/swaziland@2x.png'
import sweden from '../assets/img/exports/sweden@2x.png'

class Exports extends React.Component {

    renderItems = () => {
        const data = [
            {
                img: botswana,
                country: 'BOTSWANA',
                name: 'Safari Distributors',
                website: 'www.brandsafrica.com/botswana',
                address: 'Plot 43165, Phakalane, Gaborone, Bostwana',
                tel: '+267 393 4144/47',
                email: 'info@brandsafrica.co.zw'
            },
            // {
            //     img: israel,
            //     country: 'ISRAEL',
            //     name: 'Advanced Food Ingredients',
            //     website: 'www.afingredients.com/food-ingredients',
            //     address: 'Ha’arava 1 Givat Shmuel 5400804'
            // },
            {
                img: mozambique,
                country: 'MOZAMBIQUE',
                name: 'Marin Trading:',
                website: 'marintrading.co.mz',
                address: 'AV Angola, 2136, Maputo Moçambique',
                tel: '+258 21 46 64 29/30 | +258 21 46 53 02',
                email: 'sac@martintrading.co.mz'
            },
            {
                img: namibia,
                country: 'NAMIBIA',
                name: 'African Marketing Namibia',
                website: 'www.african-marketing.com',
                address: 'Corner of 12th Street and Circumferential Road, Walvis Bay, 9067 Namibia',
                tel: '+264 (61) 383 800',
                email: 'info@african-marketing.com'
            },
            {
                img: newzealand,
                country: 'NEW ZEALAND',
                name: 'Brooke Fine Foods',
                website: 'www.brookehl.co.nz',
                address: '9A Apollo Drive, Rosedale, Auckland 0632',
                tel: '09 - 918 8470 | 0800 442 783',
                email: 'orders@brookefinefoods.com'
            },
            {
                img: swaziland,
                country: 'SWAZILAND',
                name: 'A D Enterprises',
                address: '1st Ave Lot 226/848 Matsapha Industrial Site, Matsapha, Estwatini',
                tel: '+268 2518 5085 | +268 2518 5180 | +268 2518 5506',
                email: 'adh@adenterprises.co.za'
            },
            {
                img: sweden,
                country: 'SWEDEN',
                name: 'Wedmore Partners AB',
                website: '',
                address: 'Kommendörsgatan 8 M, SE-114 48 Stockholm, Stockholm County',
                tel: '+46 (0)708 - 576 275'
            }
        ]

        return data.map(({img,country,name,website,address,tel,email}, index) => {
            return (
                <div className='_panel-item' key={index}>
                    {img && <img src={img} alt="" />}
                    {country && <h3>{country}</h3>}
                    
                    {name && <h4>{name}</h4>}
                    <p>
                        {website && (<><strong>Website</strong>: <a href={`https://${website}`} target="_blank" rel="noreferrer">{website}</a> <br /></>)}
                        {address && (<><strong>Address</strong>: {address} <br /></>)}
                        {tel && (<><strong>Tel</strong>: {tel} <br /></>)}
                        {email && (<><strong>Email</strong>: <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a><br /></>)}
                    </p>
                </div>
            )
        })
    }

    render(){

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>Exports | Cecil Vinegar Works</title>
                    <meta name="description" content="We are proud to be in partnership with our exclusive distribution partners outside the borders of South Africa. Should you need our Naturally Fermented Vinegars, then please contact one of our partners" />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <PageHeaderSection 
                    img={banner}
                >
                    <h1>EXPORTS</h1>
                    <p>We are proud to be in partnership with our exclusive distribution partners outside the borders of South Africa. Should you need our Naturally Fermented Vinegars, then please contact one of our partners below</p>
                </PageHeaderSection>
                <section className='_section _section-exports'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                {this.renderItems()}
                            </div>
                        </div>
                    </div>
                </section>
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default Exports