import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import HeroSlider from './ui/HeroSlider'
import BrandLogos from './views/BrandLogos'
import RecipesSection from './views/RecipesSection'
import ParallaxImageSection from './ui/ParallaxImageSection'
import {Link} from 'react-router-dom'
import PreFooterSection from './views/PreFooterSection'
import Helmet from 'react-helmet'

//hero images
import safari from '../assets/img/brands/safari-hero.jpg'
import verjuic from '../assets/img/brands/verjuic-hero.jpg'
import verlaque from '../assets/img/brands/verlaque-hero.jpg'
import cecilVinegar from '../assets/img/brands/cecil-vinegar-hero.jpg'

//images 
import imgMakingVinegar from '../assets/img/about-us.jpg'
import imgWellnesBodyAndHealth from '../assets/img/wellness.jpg'
import {connect} from 'react-redux'
import {
    fetchPublicPageRecipes
} from '../actions'

class Home extends React.Component {

    componentDidMount(){
        this.props.fetchPublicPageRecipes()
    }

    render(){

        const slider = [
            {
                img: safari,
                msg: 'Safari',
                link: 'https://safarivinegar.co.za/'
            },
            {
                img: verlaque,
                msg: 'Verlaque',
                slug: 'verlaque'
            },
            {
                img: cecilVinegar,
                msg: 'Cecil Vinegar Works',
                slug: 'cecil-vinegar-works'
            },
            {
                img: verjuic,
                msg: 'The Verjuice Co.',
                slug: 'the-verjuice-co'
            }
        ]

        return(
            <Layout styleType="opaque">
                <Helmet>
                    <title>Cecil Vinegar Works</title>
                    <meta name="description" content="We've been manufacturing naturally fermented vinegar since 1936, and all our vinegars are produced from natural ingredients using the latest submerge fermentation technology." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <HeroSlider data={slider} />
                <BrandLogos />
                <ParallaxImageSection
                    img={imgMakingVinegar}
                    alt="Making Vinegar Since 1936"
                >
                    <h2 className='_section-heading'>Making Vinegar <br />
                        Since 1936</h2>
                    <p>We have been manufacturing naturally fermented vinegar since 1936, and all our vinegars are produced from natural ingredients using the latest submerge fermentation technology. </p>
                    <p>We specialise in retail, foodservice, industrial formats and contract pack vinegar under the Safari Brand and various retailer Private Label brand names for both the domestic and export markets.</p>
                    <Link to="/about-us" className='_button _button-2'>About us</Link>
                </ParallaxImageSection>
                <RecipesSection data={this.props.recipes.slice(0,3)}>
                    <h2 className='_section-heading'>Inspiring Recipes</h2>
                    <a href="https://safarivinegar.co.za/recipes/" className='_button _button-3' target="_blank" rel="noreferrer">View All</a>
                </RecipesSection>
                <ParallaxImageSection
                    img={imgWellnesBodyAndHealth}
                    alt="Wellness Body & Health"
                    left
                >
                    <h2 className='_section-heading'>Wellness  <br />
                    Body & Health</h2>
                    <p>We lock in all the goodness of naturally fermented vinegar so that you can enjoy its many health benefits from treating high cholesterol and skin irritations to helping balance your body’s pH.</p>
                    <a href="https://safarivinegar.co.za/how-to-body/" className='_button _button-2' target="_blank" rel="noreferrer" aria-label='Wellness'>Learn More About Wellness</a>
                </ParallaxImageSection>
                <PreFooterSection />
            </Layout>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        recipes: Object.values(state.publicRecipesState)
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageRecipes
})(Home)