import React from 'react'
import {Link} from 'react-router-dom'
import {BASE_PATH} from '../../paths'

class ExploreMoreProducts extends React.Component {

    renderItems = () => {
        return this.props.data.map(({logo,alt,slug,link}, index) => {
            return (
                <div className='_column' key={index}>
                    {
                        link && (
                            <a href={link} target="_blank" rel="noreferrer">
                                <img className="_brand-logo" src={BASE_PATH+logo} alt={alt} />
                            </a>
                        )
                    }
                    {
                        !link && (
                            <Link to={`/products/${slug}`}>
                                <img className="_brand-logo" src={BASE_PATH+logo} alt={alt} />
                            </Link>
                        )
                    }
                </div>
            )
        })
    }

    render(){

        return(
            <section className='_section _section-explore-more-products'>
                <div className='_grid'>
                    <div className='_column'>
                        <h2 className='_section-heading'>Explore More Products</h2>
                    </div>
                </div>
                <div className='_grid _grid-3'>
                    {this.renderItems()}
                </div>
            </section>
            
        )
    }
}

export default ExploreMoreProducts