import React from 'react'
import {connect} from 'react-redux'
import {
    fetchPublicPageLogos
} from '../../actions'
import {BASE_PATH} from '../../paths'
import {Link} from 'react-router-dom'

class BrandLogos extends React.Component {

    componentDidMount(){
        this.props.fetchPublicPageLogos()
    }

    renderLogos = () => {
        return this.props.logos.map(({logo,alt,slug,link}, index) => {
            if(link){
                return (
                    <a href={link} target="_blank" rel="noreferrer" className='_column' key={alt ? alt : index}>
                        <img src={BASE_PATH+logo} alt={alt} className='_brand-logo' />
                    </a>
                )
            }
            return (
                <Link to={`/products/${slug}`} className='_column' key={index}>
                    <img src={BASE_PATH+logo} alt={alt} className='_brand-logo' />
                </Link>
            )
        })
    }

    render(){
        return(
            <section className='_section _section-brand-logos'>
                <div className='_grid _grid-4'>
                    {this.renderLogos()}
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        logos: Object.values(state.publicLogosState)
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageLogos
})(BrandLogos)