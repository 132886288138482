import React from 'react'
import {Link} from 'react-router-dom'
import {BASE_PATH} from '../../paths'

class SplitColumnSection extends React.Component{

    renderColumns = () => {
        return this.props.data.map(({preview_image, logo, description, slug, name, link}, index) => {
            return (
                <div className='_split-grid' key={index}>
                    <div className='_split-column' style={{
                        backgroundImage: `url(${BASE_PATH+preview_image})`
                    }}>

                    </div>
                    <div className='_split-column'>
                        <div className='_panel'>
                            <img src={BASE_PATH+logo} alt={name} />
                            <div className='_content' dangerouslySetInnerHTML={{__html: description}} />
                            {
                                link && <a href={link} className="_button _button-2" target="_blank" rel="noreferrer">View Range</a>
                            }
                            {
                                !link && <Link to={`/products/${slug}`} className="_button _button-2">View Range</Link>
                            }
                        </div>
                    </div>
                </div>
            )
        })
    }

    render(){
        return(
            <section className='_section _section-split-column'>
                {this.renderColumns()}
            </section>
        )
    }
}

export default SplitColumnSection