import _ from 'lodash'
import {
    FETCH_BRANDS,
    CREATE_BRAND,
    FETCH_BRAND,
    UPDATE_BRAND,
    DELETE_BRAND,
    RESTORE_BRAND
} from '../actions/types'

const initialState = {}

const brandsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_BRANDS:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_BRAND:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_BRAND:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_BRAND:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_BRAND:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_BRAND:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default brandsReducer