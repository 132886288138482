import _ from 'lodash'
import {
    FETCH_PUBLIC_PAGE_LOGOS
} from '../actions/types'

const initialState = {}

const publicLogosReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PAGE_LOGOS:
            return {
                ...state, ..._.mapKeys(action.payload, 'slug')
            }
        default:
            return state
    }
}

export default publicLogosReducer