import _ from 'lodash'
import {
    FETCH_PUBLIC_PAGE_BRANDS
} from '../actions/types'

const initialState = {}

const publicBrandsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PAGE_BRANDS:
            return {
                ...state, ..._.mapKeys(action.payload, 'slug')
            }
        default:
            return state
    }
}

export default publicBrandsReducer