import React from 'react'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {Link} from 'react-router-dom'

class HeroSlider extends React.Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    renderItems = () => {
        return this.props.data.map(({img, msg, slug, link}, index) => {

            return(
                <div key={index}>
                    <div className="_hero-item-container" style={{
                        backgroundImage: `url(${img})`
                    }}>
                        <div className='_grid'>
                            <div className='_column'>
                                <div className='_hero-item-inner-grid'>
                                    <div className='_left'>
                                        <button className="_hero-slider-previous" onClick={this.previous}> 
                                            {this.props.data[index > 0 ? index - 1 : this.props.data.length - 1].msg} 
                                        </button>
                                    </div>
                                    <div className="_msg">
                                        <h1 className="_heading">{msg}</h1> 
                                        {
                                            link && (
                                                <a href={link} className='_button _button-1' target="_blank" rel="noreferrer">View Product</a>
                                            )
                                        }
                                        {
                                            !link && (
                                                <Link to={`products/${slug}`} className='_button _button-1'>View Product</Link>
                                            )
                                        }
                                        
                                    </div>
                                    <div className='_right'>
                                        <button className="_hero-slider-next" onClick={this.next}> 
                                            {this.props.data[index < this.props.data.length - 1 ? index + 1 : 0].msg} 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>                    
                </div>
            )
        })
    }

    render(){

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            easing: 'ease-in-out',
            pauseOnHover: false,
            // fade: true,
        };

        return (
            <div className='_hero-slider'>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.renderItems()}
                </ Slider>
            </div>
        )
    }
}

export default HeroSlider