import {
    FETCH_PUBLIC_PAGE_BRANDS_EXPLORE
} from '../actions/types'

const initialState = {}

const publicExploreReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PAGE_BRANDS_EXPLORE:
            return {
                ...state, [action.brandSlug]: action.payload
            }
        default:
            return state
    }
}

export default publicExploreReducer