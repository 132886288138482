import {
    UPDATE_BRAND,
    UPDATE_BRAND_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const updateBrandReducer = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_BRAND:
            return {
                details: action.payload,
                success: 'Brand updated successfully',
            }
        case UPDATE_BRAND_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default updateBrandReducer