import React from 'react'
import {Link} from 'react-router-dom'
import NavSocialMedia from './NavSocialMedia'
import NavLogo from'./NavLogo'
import libstar from '../../assets/img/libstar-logo@2x.png'

class Footer extends React.Component{
    render(){
        return(
            <footer className='_footer'>
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_footer-items'>
                            <div className='_footer-item'>
                                <div className='_footer-logo'>
                                    <NavLogo />
                                </div>
                            </div>
                            <div className='_footer-item'>
                                <ul>
                                    <li>
                                        <Link to="/">HOME</Link>
                                    </li>
                                    <li>
                                        <Link to="/products">PRODUCTS</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">ABOUT US</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-vinegar">ABOUT VINEGAR</Link>
                                    </li>
                                    <li>
                                        <a href="https://safarivinegar.co.za/recipes/" target="_blank" rel="noreferrer">RECIPES</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='_footer-item'>
                                <ul>
                                    <li>
                                        <a href="https://safarivinegar.co.za/how-to-body/" target="_blank" rel="noreferrer">WELLNESS</a>
                                    </li>
                                    <li>
                                        <Link to="/retail">RETAIL</Link>
                                    </li>
                                    <li>
                                        <Link to="/exports">EXPORTS</Link>
                                    </li>
                                    <li>
                                        <Link to="/industrial-and-food-services">INDUSTRIAL & FOOD SERVICES</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">CONTACT US</Link>
                                    </li>
                                    <li>
                                        <a href="https://www.libstar.co.za/legal/" target="_blank" rel="noreferrer">LEGAL</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='_footer-item'>
                                <a href="https://www.libstar.co.za/" target="_blank" rel="noreferrer">
                                    <img style={{maxWidth: '270px'}} className='_footer-libstar' src={libstar} alt="LIBSTAR" width="279" height="70" />
                                </a>
                                <p style={{maxWidth: '270px'}}>Cecil Vinegar Works a division of Libstar Operations (Pty) Ltd</p>
                            </div>
                        </div>
                        <div className='_footer-items'>
                            <div className='_footer-item'>
                                <p>Copyright © Cecil Vinegar Works</p>
                            </div>
                            <div className='_footer-item'>
                                <p>Powered by <a href="https://optimalonline.co.za"><strong>Optimal Online</strong></a></p>
                            </div>
                            <div className='_footer-item'>
                                <NavSocialMedia />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer