import React from 'react'
import Header from './Header'
import HeaderTransformed from './HeaderTransformed'
import Menu from './Menu'
import Footer from './Footer'

class Layout extends React.Component {

    state = {
        headerTransform: false,
        menuOpen: false
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
          this.handleTransform()
        })
        this.toggleDropdown()
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', () => {
          this.handleTransform()
        })
    }

    toggleDropdown = () => {
        const nodeList = document.querySelectorAll("._header-item li a")
        for(let i = 0; i < nodeList.length; i++){
            if(nodeList[i].nextElementSibling && nodeList[i].nextElementSibling.tagName === "UL"){
                nodeList[i].classList.add('_dropdown')
                nodeList[i].addEventListener("click", e => {
                    e.preventDefault()
                    nodeList[i].nextElementSibling.classList.toggle('_show') 
                    nodeList[i].parentElement.classList.toggle('_dropdown-active')
                })
            }
        }
        window.onclick = e => {
            if(!e.target.matches('._dropdown')){
                for(let a = 0; a < nodeList.length; a++){
                    if(nodeList[a].nextElementSibling && nodeList[a].nextElementSibling.tagName === "UL"){
                        nodeList[a].nextElementSibling.classList.remove('_show')   
                        nodeList[a].parentElement.classList.remove('_dropdown-active')
                    }
                }
            }
        }
        window.onscroll = () => {
            for(let b = 0; b < nodeList.length; b++){
                if(nodeList[b].nextElementSibling && nodeList[b].nextElementSibling.tagName === "UL"){
                    nodeList[b].nextElementSibling.classList.remove('_show')
                    nodeList[b].parentElement.classList.remove('_dropdown-active')   
                }
            }
        }
    }

    handleTransform = () => {

        if(this.props.styleType){
            
            let y = window.scrollY
        
            if(y > 300){
                if(!this.state.headerTransform){
                    this.setState({
                        headerTransform: !this.state.headerTransform
                    })
                }
            } else {
                if(this.state.headerTransform){
                    this.setState({
                        headerTransform: !this.state.headerTransform
                    })
                }
            }
        }
    }

    onMenuToggle = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }
    
    render(){
        return(
            <>
                <Header 
                    styleType={this.props.styleType ? this.props.styleType : null} 
                    headerTransform={this.state.headerTransform} 
                    onMenuToggle={this.onMenuToggle}
                />
                <HeaderTransformed 
                    headerTransform={this.state.headerTransform} 
                    onMenuToggle={this.onMenuToggle}
                />
                <Menu 
                    open={this.state.menuOpen} 
                    onMenuToggle={this.onMenuToggle}
                />
                <div className='_page-content'>
                    {this.props.children}
                </div>
                <Footer />
            </>
        )
    }
}

export default Layout