import React from 'react'
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom'
import history from '../history'

//public
import Home from './Home'
import PageNotFound from './PageNotFound'
import SignIn from './SignIn'
import Products from './Products'
import ProductRange from './ProductRange'
import Product from './Product'
import AboutUs from './AboutUs'
import AboutVinegar from './AboutVinegar'
import Recipes from './Recipes'
import Retail from './Retail'
import Exports from './Exports'
import IndustrialAndFood from './IndustrialAndFood'
import Contact from './Contact'
import ContactThankyou from './ContactThankyou'

//dashboard
// const DashboardHome = React.lazy(() => import('./pages/DashboardHome'))

const Dashboard = React.lazy(() => import ('./dashboard/Dashboard'))
const DashboardPageNotFound = React.lazy(() => import ('./dashboard/DashboardPageNotFound'))
const DashboardMyAccount = React.lazy(() => import ('./dashboard/DashboardMyAccount'))
const DashboardUsers = React.lazy(() => import ('./dashboard/DashboardUsers'))
const DashboardUsersCreate = React.lazy(() => import ('./dashboard/DashboardUsersCreate'))
const DashboardUsersEdit = React.lazy(() => import ('./dashboard/DashboardUsersEdit'))
const DashboardBrands = React.lazy(() => import ('./dashboard/DashboardBrands'))
const DashboardBrandsCreate = React.lazy(() => import ('./dashboard/DashboardBrandsCreate'))
const DashboardBrandsEdit = React.lazy(() => import ('./dashboard/DashboardBrandsEdit'))
const DashboardBrandsBin = React.lazy(() => import ('./dashboard/DashboardBrandsBin'))
const DashboardRanges = React.lazy(() => import ('./dashboard/DashboardRanges'))
const DashboardRangesCreate = React.lazy(() => import ('./dashboard/DashboardRangesCreate'))
const DashboardRangesEdit = React.lazy(() => import ('./dashboard/DashboardRangesEdit'))
const DashboardRangesBin = React.lazy(() => import ('./dashboard/DashboardRangesBin'))
const DashboardProducts = React.lazy(() => import ('./dashboard/DashboardProducts'))
const DashboardProductsCreate = React.lazy(() => import ('./dashboard/DashboardProductsCreate'))
const DashboardProductsEdit = React.lazy(() => import ('./dashboard/DashboardProductsEdit'))
const DashboardProductsBin = React.lazy(() => import ('./dashboard/DashboardProductsBin'))
const DashboardRecipes = React.lazy(() => import ('./dashboard/DashboardRecipes'))
const DashboardRecipesCreate = React.lazy(() => import ('./dashboard/DashboardRecipesCreate'))
const DashboardRecipesEdit = React.lazy(() => import ('./dashboard/DashboardRecipesEdit'))
const DashboardRecipesBin = React.lazy(() => import ('./dashboard/DashboardRecipesBin'))


class App extends React.Component {

    render(){
        return(
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/products/:range" component={ProductRange} />
                    <Route exact path="/products/:range/:slug" component={Product} />
                    <Route exact path="/about-us" component={AboutUs} />
                    <Route exact path="/about-vinegar" component={AboutVinegar} />
                    <Route exact path="/recipes" component={Recipes} />
                    <Route exact path="/retail" component={Retail} />
                    <Route exact path="/exports" component={Exports} />
                    <Route exact path="/industrial-and-food-services" component={IndustrialAndFood} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/contact/thankyou" component={ContactThankyou} />
                    <Route exact path="/login" component={SignIn} />
                    <Route exact path="/signin" component={SignIn} /> 
                      
                    <React.Suspense fallback={<></>}>       
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/dashboard/my-account" component={DashboardMyAccount} />
                        <Route exact path="/dashboard/users" component={DashboardUsers} />
                        <Route exact path="/dashboard/users/create" component={DashboardUsersCreate} />
                        <Route exact path="/dashboard/users/:userId/edit" component={DashboardUsersEdit} />

                        <Route exact path="/dashboard/brands" component={DashboardBrands} />
                        <Route exact path="/dashboard/brands/create" component={DashboardBrandsCreate} />
                        <Route exact path="/dashboard/brands/:brandId/edit" component={DashboardBrandsEdit} />
                        <Route exact path="/dashboard/brands/bin" component={DashboardBrandsBin} />

                        <Route exact path="/dashboard/ranges" component={DashboardRanges} />
                        <Route exact path="/dashboard/ranges/create" component={DashboardRangesCreate} />
                        <Route exact path="/dashboard/ranges/:rangeId/edit" component={DashboardRangesEdit} />
                        <Route exact path="/dashboard/ranges/bin" component={DashboardRangesBin} />

                        <Route exact path="/dashboard/products" component={DashboardProducts} />
                        <Route exact path="/dashboard/products/create" component={DashboardProductsCreate} />
                        <Route exact path="/dashboard/products/:productId/edit" component={DashboardProductsEdit} />
                        <Route exact path="/dashboard/products/bin" component={DashboardProductsBin} />

                        <Route exact path="/dashboard/recipes" component={DashboardRecipes} />
                        <Route exact path="/dashboard/recipes/create" component={DashboardRecipesCreate} />
                        <Route exact path="/dashboard/recipes/:recipeId/edit" component={DashboardRecipesEdit} />
                        <Route exact path="/dashboard/recipes/bin" component={DashboardRecipesBin} />

                        {/* <Route exact path="/dashboard/*" component={DashboardPageNotFound} /> */}
                    </React.Suspense>

                    <Route exact component={PageNotFound} /> 
                    
                </Switch>
            </Router>
        )
    }
}

export default App