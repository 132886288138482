import React from 'react'
import {Link} from 'react-router-dom'

class NavRight extends React.Component{
    render(){
        return(
            <ul>
                <li>
                    <Link to="/about">ABOUT</Link>
                    <ul>
                        <li>
                            <Link to="/about-us">About Us</Link>
                        </li>
                        <li>
                            <Link to="/about-vinegar">About Vinegar</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link to="/contact">CONTACT US</Link>
                </li>
            </ul>
        )
    }
}

export default NavRight