import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/products/Verlaque/verlaque-banner.jpg'

class PageNotFound extends React.Component {
    render(){
        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>404 Page Not Found | Cecil Vinegar Works</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <ScrollToTop />
                    <PageHeaderSection 
                        img={banner}
                    >
                        <h1>404 PAGE NOT FOUND</h1>
                        <p>Oops! The page you are looking for does not exist.</p>
                    </PageHeaderSection>
                <PreFooterSection />
            </Layout>
        )
    }
}

export default PageNotFound