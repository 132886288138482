import React from 'react'
import {Link} from 'react-router-dom'

class NavLogo extends React.Component{
    render(){

        return(
            <ul>
                <li>
                    <Link to="/" className='_logo' aria-label='Cecil Vinegar Works'>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                            viewBox="0 0 596.44 595.28" style={{
                                enableBackground:'new 0 0 596.44 595.28'
                            }} 
                            aria-hidden='true'
                        >
                        <g>
                            <path d="M561.34,167.48c-1.05-2.14-3.63-3.02-5.77-1.97c-2.14,1.08-3.02,3.64-1.98,5.77c32.88,67.07,38.36,143.24,15.45,214.48
                                c-22.98,71.41-72.08,130.19-138.27,165.53c-66.2,35.34-142.37,43.43-214.49,22.77C144.33,553.45,84.09,506.5,46.66,441.85
                                c-1.19-2.06-3.81-2.73-5.9-1.57c-2.06,1.19-2.77,3.83-1.57,5.9c38.56,66.59,100.6,114.96,174.71,136.18
                                c74.28,21.27,152.75,12.94,220.93-23.46c68.18-36.39,118.76-96.95,142.43-170.5C600.86,315.03,595.21,236.57,561.34,167.48"/>
                            <path d="M160,44.01C236.94,2.94,327.18-1.1,407.6,32.93c1.25,0.53,2.6,0.43,3.72-0.17c0.84-0.45,1.54-1.18,1.94-2.13
                                c0.93-2.19-0.1-4.73-2.29-5.66c-82.82-35.06-175.77-30.9-255.02,11.41C77.68,78.17,22.7,152.05,5.11,239.1
                                c-0.47,2.33,1.04,4.61,3.38,5.08c2.34,0.47,4.61-1.04,5.08-3.37C30.65,156.3,84.03,84.57,160,44.01"/>
                            <path d="M486.1,402.05c0.82-1.53,0.26-3.44-1.27-4.27c-1.53-0.83-3.44-0.26-4.27,1.27c-19.59,36.18-49.2,65.59-85.63,85.04
                                c-36.43,19.45-77.34,27.69-118.3,23.82c-1.74-0.16-3.27,1.11-3.43,2.84c-0.16,1.73,1.11,3.27,2.84,3.43
                                c42.2,3.97,84.34-4.51,121.86-24.54C435.42,469.62,465.92,439.33,486.1,402.05"/>
                            <path d="M196.58,112.53c33.16-17.7,70.44-26.19,107.81-24.55c0.59,0.02,1.14-0.11,1.62-0.37c0.95-0.51,1.61-1.49,1.67-2.64
                                c0.08-1.74-1.27-3.21-3.01-3.28c-38.5-1.69-76.9,7.05-111.06,25.28c-34.15,18.23-62.79,45.28-82.81,78.21
                                c-0.9,1.49-0.43,3.43,1.06,4.33c1.49,0.9,3.43,0.44,4.33-1.06C135.62,156.48,163.42,130.23,196.58,112.53"/>
                            <path d="M167.63,503.94c0.39,7.92,0.77,14.56,1.15,19.92l0.67,9.2c0.04,0.37,0.13,0.59,0.27,0.67c0.12,0.07,0.25,0,0.4-0.19
                                c0.14-0.2,0.58-0.92,1.3-2.16l11.77-20.23c0.38-0.66,0.64-1.03,0.79-1.12c0.14-0.09,0.41-0.02,0.78,0.2l0.63,0.37l0.78,0.45
                                c0.57,0.36,0.93,0.43,1.06,0.2c0.14-0.24-0.05-0.5-0.57-0.8l-1.09-0.63c-0.33-0.19-0.68-0.4-1.05-0.61
                                c-0.4-0.23-0.61-0.35-0.64-0.37c-0.07-0.04-0.29-0.17-0.67-0.39c-0.35-0.2-0.68-0.39-0.98-0.57l-1.23-0.71
                                c-0.56-0.33-0.91-0.38-1.05-0.14c-0.12,0.21,0.24,0.56,1.08,1.06c0.96,0.56,1.5,0.92,1.62,1.1c0.11,0.18,0.14,0.35,0.08,0.52
                                c-0.07,0.17-0.31,0.61-0.73,1.34l-9.1,15.66l-1.6-25.18c-0.77-0.35-1.54-0.75-2.31-1.2c-0.6-0.32-0.93-0.5-1-0.54
                                c-0.05-0.03-0.42-0.27-1.12-0.74c-0.7-0.47-1.28-0.84-1.75-1.12c-0.35-0.21-0.59-0.2-0.72,0.03c-0.14,0.24,0.09,0.54,0.7,0.9
                                c0.96,0.56,1.56,1.06,1.8,1.52c0.51,0.96,0.2,2.41-0.93,4.36l-10.1,17.38c-0.49,0.84-0.89,1.28-1.21,1.3
                                c-0.31,0.02-1.17-0.36-2.56-1.14c-0.47-0.27-0.77-0.3-0.89-0.09c-0.12,0.21,0.08,0.47,0.63,0.78l0.35,0.2
                                c0.89,0.52,1.57,0.88,2.03,1.09c0.46,0.21,0.68,0.3,0.65,0.29c0.35,0.2,0.71,0.43,1.07,0.67c1.08,0.73,1.73,1.15,1.95,1.27
                                c0.61,0.35,0.98,0.42,1.12,0.18c0.11-0.19,0.01-0.37-0.3-0.55c-1.24-0.75-2-1.27-2.29-1.54c-0.28-0.28-0.35-0.58-0.19-0.89
                                L167.63,503.94z"/>
                            <path d="M196.84,542.75l-0.12,1.12c-0.02,0.19-0.04,0.3-0.05,0.32c-0.18,0.42-1.37,0.16-3.57-0.75c-0.67-0.29-1.07-0.29-1.2-0.01
                                c-0.11,0.26,0.08,0.5,0.58,0.72c0.2,0.08,0.39,0.15,0.58,0.2l4.81,1.89l4.69,2.19c0.19,0.11,0.4,0.22,0.65,0.33
                                c0.37,0.16,0.61,0.14,0.7-0.08c0.09-0.21-0.18-0.47-0.82-0.77c-0.96-0.45-1.57-0.81-1.81-1.1c-0.25-0.29-0.35-0.76-0.3-1.41
                                c0.61-11.25,0.99-18.28,1.13-21.08c0.18-3.59,0.32-5.93,0.42-7.04c0.02-0.17-0.02-0.27-0.09-0.3c-0.13-0.06-0.28,0.04-0.48,0.28
                                l-0.45,0.61c-0.33,0.3-0.59,0.58-0.78,0.85c-4.41,4.71-10.2,10.99-17.36,18.81c-0.46,0.52-0.91,0.8-1.35,0.87
                                c-0.44,0.07-1.06-0.06-1.86-0.37c-0.8-0.31-1.24-0.36-1.34-0.13c-0.1,0.22,0.03,0.41,0.38,0.56c0.13,0.05,0.3,0.1,0.52,0.15
                                c0.41,0.08,0.81,0.2,1.21,0.37c0.4,0.17,0.78,0.35,1.15,0.54c1.47,0.75,2.79,1.38,3.94,1.87c0.64,0.28,1.02,0.29,1.13,0.04
                                c0.1-0.23-0.21-0.5-0.92-0.84c-1.14-0.58-1.74-0.9-1.8-0.95c-0.27-0.21-0.33-0.48-0.18-0.83c0.26-0.6,1.99-2.57,5.2-5.92l7.99,3.44
                                c-0.26,3.02-0.45,4.96-0.56,5.83L196.84,542.75z M190.03,532.1c3.37-3.78,6.18-6.82,8.42-9.13l-0.44,5.95
                                c-0.17,3-0.3,5.14-0.38,6.44L190.03,532.1z"/>
                            <path d="M235.59,536.13c-0.07,0.71,0.05,1.12,0.36,1.21c0.31,0.1,0.52-0.03,0.62-0.37c0.01-0.03,0.02-0.1,0.03-0.23
                                c0.01-0.12,0.02-0.22,0.05-0.3c0.57-2.95,1.05-5.15,1.43-6.63l-6.51-1.82c-1.98-0.57-3.44-1-4.37-1.31l-7.92-2.5l-1.75-0.53
                                c-0.74,1.9-1.33,3.44-1.76,4.61l-0.35,0.87c-0.11,0.27-0.21,0.56-0.3,0.85c-0.16,0.54-0.09,0.87,0.23,0.96
                                c0.28,0.09,0.59-0.25,0.9-1.01c1.06-2.51,2.51-3.82,4.32-3.93c0.69-0.05,2.33,0.3,4.91,1.05l-7.01,23.39
                                c-0.26,0.86-0.48,1.37-0.67,1.54c-0.34,0.27-0.91,0.29-1.71,0.04c-1.12-0.31-1.72-0.35-1.79-0.11c-0.06,0.21,0.2,0.4,0.8,0.58
                                c1.17,0.35,2.14,0.62,2.9,0.8c0.77,0.19,1.58,0.41,2.44,0.67c0.65,0.2,1.75,0.55,3.29,1.07c0.15,0.07,0.33,0.14,0.54,0.2
                                c0.42,0.12,0.66,0.08,0.73-0.14c0.06-0.2-0.07-0.35-0.37-0.47c-1.19-0.38-1.89-0.68-2.11-0.89c-0.43-0.38-0.5-1.04-0.21-1.98
                                l7.06-23.55c0.26,0.06,1.01,0.26,2.24,0.59c0.5,0.12,1.13,0.38,1.89,0.78c1.18,0.61,1.91,1.42,2.2,2.44
                                c0.22,0.77,0.28,1.65,0.18,2.65C235.76,535.16,235.67,535.65,235.59,536.13"/>
                            <path d="M243.61,557.04c1.2,2.47,3.74,4.04,7.61,4.71c3.23,0.56,5.73,0.08,7.5-1.44c1.76-1.53,2.98-4.24,3.66-8.13l2.6-15.02
                                c0.07-0.43,0.16-0.73,0.24-0.9c0.2-0.33,0.63-0.43,1.3-0.32c0.24,0.04,0.43,0.08,0.56,0.1c0.46,0.08,0.72,0.12,0.8,0.14
                                c0.48,0.08,0.74,0.02,0.78-0.2c0.04-0.24-0.25-0.41-0.86-0.52l-0.6-0.1c-0.16-0.03-0.41-0.07-0.76-0.13
                                c-0.38-0.07-0.71-0.13-1-0.18c-2.01-0.35-3.17-0.55-3.49-0.61c-0.13-0.02-0.31-0.05-0.52-0.09c-0.38-0.06-0.58,0.02-0.62,0.26
                                c-0.05,0.3,0.19,0.49,0.72,0.58c0.9,0.15,1.44,0.28,1.61,0.36c0.53,0.23,0.75,0.64,0.65,1.22c0.01-0.05-0.02,0.1-0.08,0.44
                                c-0.05,0.32-0.11,0.62-0.15,0.88l-0.48,2.76l-0.56,3.2l-0.4,2.28c-0.89,5.17-1.44,8.1-1.64,8.78c-1.33,4.52-4.03,6.43-8.1,5.72
                                c-2.22-0.38-3.78-1.31-4.68-2.79c-0.9-1.48-1.05-3.98-0.44-7.5l2.48-14.34c0.24-1.41,0.39-2.17,0.43-2.27
                                c0.07-0.26,0.25-0.43,0.54-0.49c0.14-0.03,0.4-0.01,0.78,0.05c0.91,0.13,1.45,0.2,1.62,0.2c0.17,0,0.26-0.1,0.3-0.28
                                c0.05-0.29-0.15-0.48-0.61-0.56c-0.19-0.03-0.35-0.06-0.48-0.08l-5.73-0.99c-0.35-0.06-0.74-0.13-1.16-0.2
                                c-0.48-0.09-0.79-0.14-0.92-0.16l-0.72-0.12c-0.37-0.06-0.58,0.03-0.63,0.26c-0.04,0.22,0.17,0.39,0.62,0.52
                                c0.84,0.15,1.34,0.25,1.5,0.3c0.55,0.21,0.82,0.56,0.82,1.05l-2.78,16.08C242.72,552.88,242.82,555.39,243.61,557.04"/>
                            <path d="M284.55,551.42c0.91,0.77,1.4,2.02,1.48,3.76c0.15,2.89,0.29,4.69,0.4,5.4c0.11,0.71,0.41,1.46,0.88,2.25
                                c1.1,1.82,2.71,2.79,4.83,2.89c1.9,0.1,2.87-0.42,2.93-1.56c0.03-0.68-0.28-1.03-0.93-1.07c-0.6-0.03-0.91,0.24-0.94,0.81
                                c-0.01,0.19,0.05,0.44,0.17,0.74c0.03,0.03,0.04,0.06,0.04,0.08c-0.01,0.16-0.22,0.23-0.62,0.21c-1.3-0.07-2-1.22-2.11-3.46
                                c-0.15-3.96-0.72-6.72-1.72-8.28c-0.99-1.56-2.9-2.66-5.72-3.29c-0.27-0.02-0.4-0.08-0.4-0.18c0.03-0.05,0.16-0.09,0.41-0.1
                                c2.61-0.08,4.71-0.7,6.29-1.86c1.58-1.15,2.42-2.68,2.52-4.58c0.1-1.92-0.56-3.48-1.98-4.68c-1.42-1.2-3.39-1.86-5.91-1.99
                                c-0.43-0.02-1.2-0.02-2.28-0.01c-0.98-0.01-1.74-0.03-2.28-0.06l-5.4-0.4c-0.16-0.04-0.28-0.06-0.37-0.06
                                c-0.43-0.02-0.66,0.11-0.67,0.38c-0.01,0.24,0.32,0.38,1,0.42c1.16,0.09,1.86,0.24,2.07,0.47c0.22,0.23,0.3,0.89,0.25,1.97
                                l-1.14,22.44c-0.05,0.92-0.13,1.45-0.24,1.58c-0.12,0.29-0.82,0.42-2.1,0.38c-0.79-0.02-1.19,0.07-1.2,0.26
                                c-0.01,0.24,0.28,0.38,0.88,0.41l1.58,0.08c0.3,0.02,0.92,0.05,1.87,0.1c0.97,0.05,1.67,0.09,2.07,0.11
                                c2.11,0.11,3.29,0.17,3.54,0.18l0.49,0.03c0.46,0.02,0.7-0.09,0.71-0.33c0.01-0.22-0.16-0.34-0.51-0.35
                                c-1-0.08-1.62-0.14-1.86-0.18c-0.86-0.18-1.28-0.54-1.25-1.08l0.65-12.81C282.11,550.19,283.64,550.65,284.55,551.42
                                M280.55,538.85c0.03-0.62,0.22-1.05,0.55-1.27c0.34-0.23,0.95-0.32,1.85-0.27c3.36,0.17,4.94,2.19,4.74,6.07
                                c-0.11,2.19-0.77,3.75-1.97,4.67c-1.2,0.91-3.1,1.3-5.7,1.13L280.55,538.85z"/>
                            <path d="M315.56,563.6c-0.73,0.05-1.09,0.23-1.07,0.54c0.02,0.28,0.3,0.41,0.84,0.37c0.21-0.01,0.42-0.04,0.61-0.08l5.14-0.5
                                l5.17-0.17c0.22,0.01,0.46,0.01,0.73-0.01c0.41-0.03,0.61-0.16,0.59-0.39c-0.01-0.23-0.38-0.33-1.08-0.32
                                c-1.06,0.04-1.76-0.01-2.12-0.16c-0.35-0.15-0.65-0.52-0.91-1.12c-4.56-10.3-7.41-16.74-8.56-19.31c-1.46-3.28-2.4-5.43-2.82-6.46
                                c-0.07-0.16-0.14-0.24-0.22-0.23c-0.14,0.01-0.23,0.17-0.29,0.46l-0.13,0.76c-0.16,0.41-0.26,0.78-0.31,1.1
                                c-1.79,6.2-4.11,14.41-6.94,24.64c-0.18,0.66-0.44,1.12-0.81,1.38c-0.36,0.26-0.97,0.42-1.82,0.51c-0.85,0.08-1.27,0.25-1.25,0.49
                                c0.02,0.24,0.22,0.35,0.59,0.33c0.14-0.01,0.31-0.05,0.52-0.11c0.4-0.12,0.82-0.19,1.25-0.22c0.43-0.02,0.85-0.04,1.26-0.04
                                c1.66,0,3.11-0.04,4.36-0.12c0.7-0.05,1.05-0.2,1.03-0.48c-0.02-0.24-0.42-0.35-1.21-0.33c-1.28,0-1.96-0.01-2.04-0.03
                                c-0.33-0.06-0.51-0.28-0.53-0.66c-0.04-0.65,0.61-3.19,1.95-7.63l8.69-0.56c1.13,2.82,1.85,4.63,2.15,5.45l0.25,0.56l0.4,1.05
                                c0.07,0.19,0.1,0.29,0.11,0.31C319.12,563.1,317.94,563.41,315.56,563.6 M307.68,554.89c1.29-4.9,2.42-8.88,3.36-11.95l2.32,5.5
                                c1.2,2.75,2.06,4.72,2.59,5.91L307.68,554.89z"/>
                            <path d="M334.3,561.8c-0.71,0.18-1.05,0.37-1.01,0.56c0.04,0.24,0.29,0.32,0.74,0.23l16.65-2.89c0.77-0.14,1.47-0.24,2.09-0.3
                                c0.62-0.06,1.02-0.1,1.17-0.13l0.44-0.08c-0.21-1.61-0.38-2.77-0.51-3.47l-0.28-1.56c-0.04-0.18-0.07-0.37-0.11-0.56
                                c-0.03-0.18-0.06-0.3-0.06-0.36l-0.18-1c-0.05-0.41-0.23-0.58-0.57-0.52c-0.28,0.05-0.4,0.25-0.37,0.6
                                c0.19,1.51,0.14,2.74-0.15,3.71c-0.29,0.96-1.05,1.72-2.27,2.27c-1.23,0.55-3.4,1.11-6.52,1.68c-1.28,0.23-2.29,0.4-3.01,0.5
                                l-4.29-23.63c-0.22-1.18-0.26-1.89-0.16-2.14c0.1-0.26,0.54-0.47,1.31-0.63c0.77-0.17,1.23-0.28,1.36-0.35
                                c0.14-0.07,0.19-0.18,0.16-0.34c-0.05-0.27-0.31-0.36-0.79-0.27l-0.44,0.08c-0.46,0.08-1.03,0.21-1.71,0.37
                                c-0.69,0.17-1.15,0.27-1.39,0.32c-2.62,0.47-4.37,0.75-5.27,0.83l-0.36,0.07c-0.32,0.06-0.46,0.21-0.41,0.45
                                c0.05,0.29,0.33,0.39,0.84,0.3c1.23-0.22,1.96-0.27,2.19-0.15c0.23,0.12,0.43,0.68,0.61,1.67l4.09,22.51
                                c0.15,0.83,0.14,1.35-0.03,1.56C335.9,561.33,335.31,561.55,334.3,561.8"/>
                            <path d="M363.03,555.97c-0.69,0.26-1,0.48-0.95,0.67c0.07,0.23,0.33,0.29,0.77,0.15l16.23-4.73c0.75-0.22,1.43-0.4,2.05-0.53
                                c0.61-0.12,0.99-0.21,1.15-0.25l0.43-0.13c-0.39-1.59-0.68-2.72-0.89-3.39l-0.46-1.52c-0.05-0.18-0.1-0.36-0.16-0.55
                                c-0.05-0.18-0.09-0.3-0.1-0.35l-0.29-0.97c-0.09-0.4-0.3-0.55-0.62-0.45c-0.27,0.08-0.37,0.3-0.3,0.64
                                c0.36,1.48,0.45,2.72,0.26,3.7c-0.18,0.99-0.86,1.83-2.01,2.51c-1.15,0.69-3.25,1.48-6.29,2.39c-1.25,0.37-2.23,0.65-2.94,0.84
                                l-6.89-23.01c-0.34-1.14-0.47-1.84-0.4-2.11c0.08-0.26,0.49-0.52,1.24-0.77c0.74-0.25,1.18-0.42,1.31-0.5
                                c0.13-0.08,0.18-0.2,0.13-0.35c-0.08-0.26-0.35-0.32-0.82-0.18l-0.43,0.13c-0.44,0.13-1,0.32-1.66,0.56
                                c-0.66,0.24-1.11,0.4-1.34,0.47c-2.55,0.76-4.26,1.23-5.14,1.41l-0.35,0.1c-0.31,0.09-0.43,0.26-0.36,0.49
                                c0.09,0.29,0.37,0.36,0.87,0.21c1.2-0.36,1.92-0.49,2.16-0.39c0.24,0.1,0.51,0.63,0.8,1.59l6.55,21.91
                                c0.24,0.81,0.29,1.32,0.15,1.55C364.56,555.34,364,555.62,363.03,555.97"/>
                            <path d="M395.97,544.79c-0.56,0.27-0.79,0.52-0.69,0.76c0.1,0.24,0.32,0.28,0.67,0.13c0.2-0.09,0.37-0.17,0.51-0.26
                                c0.92-0.46,2.12-1,3.6-1.63l5.28-2.13l0.45-0.19c0.35-0.15,0.49-0.32,0.4-0.51c-0.08-0.19-0.47-0.17-1.16,0.08
                                c-1.3,0.47-2.09,0.67-2.37,0.62c-0.27-0.06-0.59-0.5-0.94-1.33l-3.04-7.09c-0.48-1.13-0.76-1.88-0.83-2.24
                                c-0.07-0.37-0.06-1.12,0.05-2.26c0.15-1.66,0.25-3.55,0.31-5.67c0.13-4.8,0.29-7.4,0.46-7.8c0.13-0.59,0.88-1.2,2.24-1.81
                                c0.42-0.18,0.6-0.36,0.51-0.56c-0.13-0.31-1.46,0.12-3.97,1.29l-1.24,0.53l-2.17,0.93c-0.47,0.2-0.67,0.41-0.58,0.61
                                c0.12,0.28,0.45,0.29,0.98,0.02c0.29-0.15,0.9-0.35,1.83-0.6c0.45-0.13,0.74-0.04,0.88,0.29c0.21,0.51,0.25,1.74,0.1,3.7l-0.3,5.4
                                c-0.09,1.98-0.21,3.65-0.36,5.04l-6.15-4.98c-2.86-2.34-4.38-3.72-4.57-4.14c-0.24-0.56,0.42-1.16,1.96-1.82
                                c0.85-0.36,1.23-0.66,1.13-0.89c-0.11-0.25-0.39-0.28-0.83-0.09c-0.22,0.1-0.42,0.2-0.58,0.32l-4.31,1.92
                                c-0.4,0.17-1.05,0.43-1.96,0.8c-1.59,0.62-2.65,1.05-3.18,1.27c-0.4,0.17-0.56,0.36-0.47,0.56c0.12,0.27,0.63,0.22,1.54-0.17
                                c0.91-0.39,1.56-0.52,1.94-0.39c0.91,0.67,1.7,1.29,2.39,1.85l6.92,5.8c2.21,1.86,3.56,3,4.05,3.45l3.37,7.86
                                c0.43,0.99,0.56,1.65,0.4,1.95C398.08,543.68,397.33,544.15,395.97,544.79"/>
                            <path d="M430.8,527.8c-0.77,0.54-1.09,0.91-0.97,1.09c0.12,0.18,0.39,0.14,0.83-0.14l5.13-3.24c0.18-0.12,0.54-0.34,1.07-0.67
                                c0.55-0.35,0.98-0.62,1.3-0.83l0.34-0.22c0.46-0.29,0.62-0.53,0.49-0.74c-0.11-0.18-0.35-0.16-0.72,0.07
                                c-0.85,0.56-1.42,0.88-1.73,0.95c-0.64,0.14-1.17-0.12-1.59-0.78l-4.26-6.75l-2.21-3.51c0.61-0.45,1.33-0.94,2.18-1.47
                                c1.6-1.01,2.78-1.51,3.53-1.51c0.75,0.01,1.67,0.65,2.73,1.94c0.69,0.84,1.11,1.32,1.24,1.43c0.13,0.11,0.29,0.11,0.47-0.01
                                c0.23-0.15,0.26-0.35,0.08-0.63c-0.12-0.18-0.21-0.31-0.29-0.4c-0.71-0.96-1.62-2.32-2.72-4.06c-0.85-1.35-1.74-2.84-2.68-4.47
                                c-0.06-0.15-0.16-0.33-0.29-0.54c-0.22-0.34-0.44-0.44-0.67-0.3c-0.23,0.15-0.2,0.5,0.09,1.05c1.04,1.94,1.34,3.33,0.9,4.17
                                c-0.43,0.84-2.13,2.17-5.08,4l-6.39-10.12c1.43-0.99,2.66-1.83,3.72-2.49c1.43-0.9,2.36-1.45,2.8-1.67c1.22-0.6,2.26-0.84,3.12-0.7
                                c1.34,0.23,2.67,1.09,4,2.59c0.46,0.53,0.8,0.72,1.03,0.57c0.23-0.14,0.24-0.37,0.04-0.7c-0.15-0.23-0.34-0.49-0.59-0.78
                                c-0.59-0.68-1.23-1.49-1.91-2.4c-1.01-1.35-1.63-2.17-1.83-2.45c-0.79,0.69-3.12,2.25-7,4.7c-1.44,0.91-3.3,2.09-5.56,3.51
                                c-2.26,1.43-3.36,2.12-3.32,2.09l-0.55,0.35c-0.41,0.26-0.55,0.5-0.42,0.7c0.15,0.23,0.4,0.23,0.78-0.01
                                c0.99-0.59,1.62-0.91,1.91-0.94c0.29-0.04,0.57,0.15,0.86,0.55l12.52,19.81c0.56,0.9,0.83,1.48,0.8,1.73
                                C431.96,526.85,431.56,527.25,430.8,527.8"/>
                            <path d="M453.72,512.97c-0.11,0.12-0.12,0.24-0.03,0.35c0.14,0.17,0.4,0.09,0.81-0.23l0.31-0.25c0.26-0.2,0.75-0.59,1.47-1.17
                                c0.72-0.57,1.45-1.16,2.19-1.75c6.15-4.91,10.32-8.13,12.5-9.66l-0.84-1.05c-0.69-0.87-1.4-1.8-2.12-2.79
                                c-0.72-0.99-1.11-1.53-1.18-1.61c-0.36-0.45-0.64-0.59-0.85-0.42c-0.23,0.19-0.16,0.56,0.22,1.11c1.69,2.52,1.97,4.5,0.82,5.93
                                c-0.66,0.83-3.32,3.11-7.98,6.83l-8.5-10.66l1.42-1.14c1.61-1.28,2.65-2.01,3.13-2.18c1.1-0.39,2.24-0.03,3.4,1.09
                                c0.93,0.89,1.48,1.41,1.68,1.55c0.2,0.14,0.37,0.15,0.52,0.03c0.23-0.18,0.24-0.41,0.02-0.69c-0.12-0.15-0.27-0.32-0.46-0.52
                                c-0.74-0.79-2.02-2.33-3.83-4.6c-0.3-0.38-0.73-0.97-1.28-1.74c-0.55-0.78-0.98-1.36-1.28-1.74c-0.3-0.38-0.57-0.48-0.81-0.29
                                c-0.19,0.15-0.13,0.47,0.18,0.95c1.2,1.89,1.68,3.22,1.45,4c-0.23,0.77-1.54,2.11-3.91,4l-0.76,0.61l-7.46-9.35l2.61-2.08
                                c2.88-2.3,4.82-3.53,5.81-3.69c1.32-0.21,2.95,0.48,4.88,2.07c0.53,0.45,0.91,0.58,1.15,0.39c0.19-0.15,0.2-0.33,0.03-0.54
                                c-0.14-0.17-0.26-0.3-0.36-0.39l-1.22-1.27c-1.43-1.49-2.53-2.68-3.3-3.56c-0.46,0.51-1.01,1.02-1.65,1.52
                                c-0.76,0.61-2.25,1.8-4.45,3.56c-2.21,1.76-3.4,2.71-3.59,2.86l-5.44,4.34c-0.47,0.37-0.63,0.65-0.48,0.83
                                c0.14,0.18,0.39,0.13,0.72-0.14c0.97-0.78,1.6-1.18,1.87-1.21c0.27-0.02,0.66,0.27,1.15,0.89l14.31,17.94
                                c0.64,0.81,0.98,1.36,1.02,1.64c0.04,0.28-0.2,0.67-0.72,1.15C454.17,512.57,453.79,512.92,453.72,512.97"/>
                            <path d="M479.9,477.8c2.25,1.82,3.68,2.92,4.28,3.32c0.61,0.39,1.36,0.67,2.26,0.84c2.09,0.39,3.89-0.16,5.38-1.66
                                c1.34-1.34,1.61-2.42,0.8-3.22c-0.48-0.48-0.95-0.49-1.41-0.03c-0.42,0.42-0.43,0.84-0.02,1.24c0.14,0.13,0.35,0.26,0.66,0.37
                                c0.04,0,0.07,0.01,0.09,0.03c0.11,0.11,0.03,0.31-0.26,0.61c-0.92,0.92-2.25,0.67-3.98-0.75c-3.05-2.53-5.48-3.95-7.31-4.26
                                c-1.83-0.3-3.92,0.38-6.28,2.06c-0.19,0.2-0.33,0.25-0.4,0.17c-0.02-0.06,0.05-0.18,0.2-0.37c1.68-2,2.62-3.97,2.82-5.92
                                c0.19-1.95-0.38-3.59-1.73-4.93c-1.37-1.36-2.97-1.91-4.81-1.66c-1.84,0.26-3.65,1.28-5.44,3.07c-0.3,0.31-0.81,0.87-1.53,1.69
                                c-0.66,0.72-1.19,1.27-1.57,1.66l-3.9,3.75c-0.14,0.1-0.23,0.17-0.29,0.23c-0.3,0.31-0.36,0.56-0.17,0.75
                                c0.17,0.17,0.5,0.02,0.98-0.47c0.84-0.8,1.42-1.21,1.73-1.23c0.32-0.01,0.86,0.37,1.63,1.14l15.93,15.84
                                c0.65,0.65,0.99,1.06,1.01,1.24c0.14,0.29-0.24,0.89-1.12,1.82c-0.54,0.57-0.74,0.93-0.6,1.07c0.17,0.17,0.47,0.05,0.89-0.38
                                l1.12-1.13c0.21-0.21,0.65-0.65,1.32-1.33c0.69-0.69,1.18-1.18,1.46-1.47c1.49-1.5,2.33-2.34,2.5-2.51l0.34-0.35
                                c0.33-0.33,0.4-0.58,0.23-0.75c-0.15-0.15-0.35-0.1-0.6,0.15c-0.73,0.69-1.19,1.12-1.37,1.27c-0.71,0.52-1.26,0.59-1.64,0.2
                                l-9.09-9.04c1.55-1.48,2.92-2.31,4.1-2.47C477.29,476.22,478.55,476.7,479.9,477.8 M471.41,478.26l-7.33-7.29
                                c-0.45-0.44-0.64-0.86-0.58-1.27c0.06-0.4,0.4-0.93,1.03-1.56c2.37-2.39,4.93-2.21,7.68,0.52c1.56,1.55,2.28,3.08,2.16,4.59
                                C474.25,474.76,473.27,476.43,471.41,478.26"/>
                            <path d="M509,458.23c0.19,0.15,0.47,0,0.81-0.45l0.17-0.23c0.21-0.28,0.66-0.86,1.33-1.74c0.66-0.86,1.23-1.61,1.73-2.26
                                c0.33-0.43,0.8-1.04,1.4-1.83c0.61-0.79,1.03-1.34,1.26-1.64l0.27-0.36c0.35-0.45,0.43-0.74,0.24-0.89
                                c-0.18-0.14-0.44,0.01-0.77,0.44l-0.15,0.19c-0.86,1.12-1.49,1.75-1.9,1.88c-0.28,0.06-0.86-0.25-1.73-0.91l-15.07-11.54
                                c-2.37-1.82-3.6-2.84-3.67-3.07c-0.11-0.39-0.01-0.88,0.3-1.46c0.12-0.25,0.41-0.68,0.88-1.28c0.28-0.37,0.31-0.63,0.1-0.8
                                c-0.13-0.1-0.27-0.12-0.41-0.06c-0.15,0.05-0.49,0.44-1.03,1.14l-3.64,4.82c0.27,0.48,0.64,1.05,1.09,1.74l8.03,11.63
                                c1.03,1.47,2.09,2.95,3.18,4.43c-7.98-2.53-12.81-4.04-14.48-4.54c-3.99-1.24-6.16-1.91-6.52-2.02c-0.38,0.54-1.07,1.45-2.06,2.73
                                l-1.3,1.7c-0.22,0.28-0.36,0.47-0.43,0.55c-0.48,0.63-0.6,1.04-0.37,1.22c0.17,0.13,0.4,0.04,0.69-0.29
                                c0.77-0.87,1.32-1.41,1.64-1.61c0.32-0.2,0.67-0.23,1.04-0.08c0.18,0.07,1.17,0.79,2.96,2.16l15.5,11.87
                                c0.95,0.72,1.48,1.2,1.59,1.42c0.11,0.22-0.04,0.58-0.44,1.1c-0.92,1.24-1.27,1.95-1.05,2.12c0.19,0.14,0.44,0.03,0.74-0.36
                                l2.72-3.81c0.18-0.24,0.41-0.49,0.68-0.76c0.22-0.21,0.45-0.45,0.66-0.73c0.35-0.45,0.4-0.77,0.17-0.95
                                c-0.21-0.17-0.59,0.11-1.14,0.82c-0.68,0.88-1.22,1.3-1.61,1.23c-0.09,0.03-2.11-1.46-6.05-4.48l-10.5-8.04
                                c-0.71-0.55-1.27-0.97-1.68-1.29l4.43,1.36c10.04,3.1,16.28,5.08,18.72,5.97c0.65,0.22,1.03,0.26,1.15,0.11
                                c0.1-0.13-0.24-0.74-1.02-1.81l-4-5.44c-3.97-5.65-6.93-9.91-8.87-12.77c-0.54-0.82-0.81-1.24-0.83-1.25l16.76,12.84
                                c1.34,1.03,2.02,1.63,2.05,1.82c0.05,0.42-0.34,1.18-1.18,2.28C508.92,457.67,508.79,458.07,509,458.23"/>
                            <path d="M520.39,441.6c-0.06,0.15-0.03,0.27,0.1,0.34c0.19,0.11,0.42-0.06,0.67-0.51l0.2-0.35c0.17-0.28,0.48-0.82,0.94-1.62
                                c0.46-0.8,0.93-1.6,1.41-2.42c3.95-6.81,6.66-11.32,8.14-13.54l-1.16-0.67c-0.97-0.56-1.96-1.17-2.99-1.83
                                c-1.03-0.66-1.59-1.02-1.68-1.07c-0.49-0.29-0.8-0.31-0.94-0.08c-0.15,0.26,0.05,0.58,0.61,0.96c2.5,1.73,3.47,3.47,2.92,5.23
                                c-0.3,1.01-1.96,4.1-4.96,9.26l-11.79-6.85l0.91-1.58c1.03-1.77,1.74-2.83,2.12-3.17c0.88-0.76,2.07-0.84,3.56-0.22
                                c1.19,0.5,1.89,0.78,2.13,0.83c0.23,0.06,0.4,0,0.49-0.16c0.15-0.26,0.08-0.48-0.23-0.65c-0.16-0.09-0.37-0.2-0.61-0.31
                                c-0.98-0.47-2.73-1.44-5.24-2.89c-0.42-0.25-1.03-0.63-1.82-1.15c-0.8-0.53-1.4-0.91-1.83-1.15c-0.42-0.24-0.71-0.24-0.86,0.02
                                c-0.12,0.21,0.05,0.48,0.51,0.82c1.81,1.33,2.74,2.39,2.8,3.2c0.07,0.81-0.66,2.53-2.19,5.15l-0.49,0.85l-10.35-6.01l1.68-2.88
                                c1.85-3.2,3.21-5.04,4.07-5.55c1.15-0.68,2.92-0.62,5.3,0.16c0.66,0.22,1.06,0.21,1.22-0.05c0.12-0.21,0.07-0.39-0.17-0.52
                                c-0.19-0.11-0.35-0.18-0.48-0.23l-1.6-0.74c-1.88-0.87-3.33-1.58-4.37-2.12c-0.25,0.64-0.57,1.32-0.98,2.02
                                c-0.49,0.84-1.44,2.49-2.86,4.93c-1.42,2.44-2.19,3.77-2.31,3.98l-3.49,6.02c-0.3,0.51-0.35,0.83-0.15,0.95
                                c0.2,0.12,0.41-0.02,0.62-0.39c0.63-1.08,1.06-1.68,1.31-1.81c0.25-0.12,0.71,0.01,1.39,0.41l19.85,11.51
                                c0.89,0.52,1.41,0.91,1.54,1.16c0.14,0.25,0.05,0.7-0.26,1.33C520.65,441.07,520.43,441.53,520.39,441.6"/>
                            <path d="M543.69,396.58c0.36-0.08,0.57-0.2,0.63-0.35c0.06-0.12-0.03-0.24-0.24-0.36c-0.21-0.12-0.98-0.47-2.3-1.04l-21.51-9.25
                                c-0.69-0.3-1.1-0.52-1.2-0.65c-0.11-0.14-0.08-0.4,0.1-0.8l0.29-0.67l0.35-0.82c0.29-0.61,0.32-0.97,0.07-1.08
                                c-0.24-0.11-0.49,0.11-0.73,0.66l-0.5,1.16c-0.15,0.35-0.31,0.72-0.49,1.12c-0.18,0.42-0.28,0.65-0.29,0.67
                                c-0.03,0.07-0.14,0.31-0.31,0.71c-0.16,0.37-0.31,0.72-0.46,1.05l-0.56,1.31c-0.26,0.6-0.26,0.95-0.01,1.06
                                c0.22,0.1,0.53-0.3,0.92-1.2c0.44-1.02,0.74-1.6,0.9-1.74c0.17-0.13,0.33-0.18,0.51-0.14c0.17,0.05,0.64,0.23,1.42,0.57l16.63,7.16
                                l-24.81,4.61c-0.26,0.8-0.56,1.62-0.91,2.44c-0.24,0.63-0.38,0.99-0.42,1.06c-0.02,0.05-0.22,0.45-0.6,1.2
                                c-0.38,0.75-0.68,1.38-0.9,1.88c-0.16,0.37-0.12,0.61,0.11,0.71c0.26,0.11,0.53-0.15,0.81-0.8c0.44-1.02,0.87-1.67,1.29-1.97
                                c0.89-0.62,2.37-0.48,4.44,0.41l18.46,7.95c0.9,0.39,1.38,0.73,1.44,1.04c0.06,0.31-0.21,1.2-0.82,2.68
                                c-0.21,0.5-0.21,0.8,0.02,0.89c0.22,0.1,0.46-0.14,0.71-0.72l0.16-0.37c0.41-0.95,0.68-1.66,0.83-2.14
                                c0.15-0.48,0.22-0.71,0.21-0.69c0.16-0.37,0.34-0.75,0.54-1.14c0.59-1.16,0.93-1.86,1.03-2.08c0.28-0.65,0.29-1.03,0.05-1.13
                                c-0.2-0.08-0.37,0.04-0.51,0.36c-0.6,1.32-1.02,2.14-1.26,2.46c-0.24,0.32-0.53,0.42-0.86,0.3L515,401.87
                                c7.81-1.34,14.36-2.51,19.63-3.53L543.69,396.58z"/>
                            <path d="M549.36,379.84c0.21,0.06,0.4-0.21,0.58-0.81c0.34-1.17,0.61-2.14,0.79-2.91c0.18-0.77,0.41-1.58,0.66-2.44
                                c0.2-0.65,0.55-1.75,1.06-3.29c0.07-0.15,0.14-0.33,0.2-0.54c0.13-0.42,0.08-0.66-0.15-0.72c-0.2-0.06-0.35,0.07-0.47,0.37
                                c-0.38,1.19-0.68,1.89-0.88,2.12c-0.38,0.43-1.04,0.5-1.98,0.22l-23.57-6.97c0.06-0.26,0.25-1.01,0.58-2.25
                                c0.12-0.5,0.37-1.14,0.77-1.89c0.6-1.18,1.41-1.92,2.44-2.21c0.77-0.22,1.65-0.29,2.64-0.19c0.48,0.08,0.97,0.17,1.45,0.26
                                c0.72,0.07,1.12-0.05,1.21-0.36c0.09-0.31-0.03-0.52-0.37-0.62c-0.03,0-0.1-0.01-0.23-0.02c-0.12-0.01-0.23-0.02-0.3-0.05
                                c-2.94-0.56-5.16-1.03-6.63-1.41l-1.8,6.51c-0.56,1.99-0.99,3.44-1.29,4.37l-2.47,7.93l-0.52,1.76c1.91,0.73,3.45,1.31,4.62,1.75
                                l0.87,0.34c0.28,0.11,0.56,0.21,0.85,0.29c0.54,0.16,0.87,0.09,0.96-0.22c0.08-0.29-0.25-0.58-1.01-0.89
                                c-2.52-1.06-3.83-2.49-3.95-4.31c-0.05-0.69,0.29-2.34,1.03-4.92l23.41,6.93c0.86,0.25,1.37,0.48,1.55,0.67
                                c0.27,0.33,0.28,0.91,0.05,1.71C549.16,379.18,549.12,379.77,549.36,379.84"/>
                            <path d="M556.03,355.55c0.21,0.04,0.37-0.2,0.46-0.71l0.07-0.4c0.05-0.32,0.16-0.94,0.32-1.84c0.16-0.91,0.32-1.83,0.49-2.77
                                c1.36-7.75,2.36-12.92,2.98-15.51l-1.33-0.23c-1.1-0.2-2.24-0.42-3.43-0.69c-1.19-0.27-1.84-0.41-1.95-0.43
                                c-0.56-0.1-0.87-0.02-0.92,0.25c-0.05,0.29,0.25,0.52,0.9,0.69c2.94,0.77,4.45,2.07,4.54,3.9c0.06,1.06-0.43,4.53-1.47,10.41
                                l-13.43-2.37l0.32-1.79c0.36-2.02,0.66-3.26,0.9-3.71c0.57-1.02,1.66-1.51,3.27-1.44c1.28,0.06,2.05,0.08,2.28,0.05
                                c0.24-0.03,0.37-0.13,0.4-0.32c0.05-0.29-0.1-0.47-0.44-0.53c-0.19-0.03-0.42-0.06-0.68-0.09c-1.08-0.1-3.06-0.41-5.92-0.91
                                c-0.48-0.09-1.18-0.24-2.11-0.45c-0.93-0.22-1.63-0.37-2.11-0.45c-0.49-0.09-0.75,0.02-0.81,0.31c-0.04,0.24,0.22,0.44,0.77,0.59
                                c2.15,0.63,3.39,1.31,3.73,2.04c0.34,0.74,0.24,2.6-0.28,5.59l-0.17,0.96l-11.78-2.08l0.58-3.28c0.64-3.64,1.27-5.84,1.91-6.61
                                c0.85-1.03,2.53-1.6,5.04-1.68c0.69-0.01,1.07-0.17,1.12-0.46c0.04-0.24-0.07-0.38-0.34-0.43c-0.22-0.04-0.39-0.06-0.53-0.05
                                l-1.75-0.14c-2.06-0.17-3.67-0.33-4.83-0.48c-0.01,0.69-0.09,1.43-0.23,2.24c-0.17,0.96-0.5,2.83-0.99,5.61
                                c-0.49,2.78-0.75,4.29-0.8,4.53l-1.21,6.86c-0.11,0.59-0.05,0.9,0.18,0.94c0.23,0.04,0.38-0.15,0.46-0.58
                                c0.22-1.23,0.42-1.95,0.61-2.14c0.19-0.2,0.67-0.23,1.45-0.1l22.6,3.99c1.01,0.18,1.63,0.36,1.85,0.55
                                c0.22,0.19,0.29,0.63,0.22,1.34c-0.09,0.95-0.15,1.47-0.16,1.54C555.82,355.43,555.89,355.52,556.03,355.55"/>
                            <path d="M560.79,325.81c0.27,0.01,0.42-0.24,0.45-0.75c0.01-0.21,0.02-0.42,0.03-0.61l0.16-3.21l0.1-2.03
                                c0.08-1.63,0.14-2.86,0.19-3.72c0.04-0.86,0.07-1.33,0.07-1.44c0.2-4.09-0.6-7.41-2.44-9.94c-2.49-3.47-6.13-5.32-10.92-5.56
                                c-3.13-0.16-5.93,0.46-8.41,1.86c-2.48,1.39-4.2,3.31-5.14,5.73c-0.78,2-1.3,5.44-1.54,10.31l-0.22,4.35
                                c-0.03,0.6-0.06,1.21-0.1,1.83c-0.03,0.62-0.05,0.92-0.04,0.9c-0.03,0.46,0.07,0.7,0.29,0.71c0.3,0.01,0.46-0.3,0.49-0.91
                                c0.06-1.19,0.21-1.89,0.43-2.08c0.23-0.19,1-0.25,2.33-0.19l21.91,1.1c0.84,0.05,1.35,0.13,1.54,0.24
                                c0.39,0.29,0.56,0.99,0.51,2.11C560.45,325.36,560.55,325.8,560.79,325.81 M560.26,317.75c-0.12,0.18-0.26,0.3-0.43,0.33
                                c-0.17,0.03-0.63,0.03-1.39-0.01l-21.69-1.09c-1.11-0.05-1.76-0.17-1.95-0.34c-0.36-0.4-0.5-1.51-0.41-3.32
                                c0.19-3.74,1.38-6.43,3.58-8.09c2.2-1.66,5.55-2.37,10.06-2.14c4.7,0.24,8.09,1.36,10.17,3.37c1.98,1.89,2.89,4.3,2.74,7.23
                                C560.85,315.76,560.62,317.12,560.26,317.75"/>
                            <path d="M84.49,134.3c1.5-0.69,3.67-0.41,6.5,0.86c0.78,0.36,1.28,0.39,1.49,0.09c0.17-0.24,0.13-0.47-0.14-0.66
                                c-0.22-0.16-0.4-0.27-0.56-0.34l-1.87-1.11c-2.2-1.3-3.9-2.35-5.11-3.14c-0.38,0.76-0.87,1.55-1.46,2.36
                                c-0.71,0.97-2.08,2.87-4.13,5.69c-2.05,2.82-3.16,4.35-3.33,4.6l-5.04,6.95c-0.43,0.6-0.53,0.98-0.3,1.15
                                c0.23,0.17,0.5,0.03,0.82-0.4c0.9-1.25,1.52-1.93,1.83-2.05c0.32-0.12,0.87,0.1,1.66,0.67l22.93,16.62
                                c1.04,0.75,1.62,1.29,1.76,1.61c0.14,0.33-0.02,0.86-0.48,1.61c-0.61,1-0.95,1.55-1.01,1.63c-0.09,0.18-0.07,0.32,0.08,0.43
                                c0.22,0.16,0.51-0.02,0.89-0.54l0.3-0.41c0.24-0.32,0.69-0.95,1.35-1.87c0.67-0.92,1.35-1.86,2.03-2.81
                                c5.7-7.86,9.6-13.07,11.69-15.61l-1.34-0.97c-1.11-0.81-2.26-1.68-3.44-2.62c-1.18-0.94-1.83-1.45-1.93-1.53
                                c-0.57-0.41-0.96-0.48-1.15-0.21c-0.22,0.3-0.01,0.72,0.62,1.26c2.85,2.44,3.83,4.7,2.93,6.78c-0.51,1.2-2.92,4.79-7.25,10.76
                                l-13.63-9.88l1.32-1.82c1.49-2.05,2.49-3.26,3-3.63c1.18-0.83,2.65-0.78,4.41,0.17c1.39,0.76,2.23,1.19,2.51,1.29
                                c0.28,0.1,0.49,0.06,0.62-0.14c0.21-0.3,0.15-0.57-0.21-0.83c-0.19-0.14-0.43-0.29-0.72-0.47c-1.15-0.7-3.17-2.1-6.07-4.2
                                c-0.49-0.35-1.19-0.9-2.1-1.65c-0.91-0.74-1.61-1.29-2.1-1.64c-0.49-0.35-0.84-0.38-1.06-0.08c-0.18,0.25,0,0.6,0.53,1.07
                                c2.05,1.86,3.06,3.28,3.04,4.28c-0.02,1-1.13,3.02-3.34,6.06l-0.71,0.97l-11.96-8.67l2.42-3.34
                                C81.48,136.92,83.37,134.82,84.49,134.3"/>
                            <path d="M104.65,109.26c-1.94,0.93-3.31,1.8-4.09,2.63c-1.89,1.99-2.81,4.26-2.73,6.8c0.08,2.54,1.12,4.78,3.13,6.69
                                c1.58,1.5,3.33,2.31,5.25,2.43c1.92,0.12,4.55-0.43,7.89-1.65c2.77-1.02,5.01-1.46,6.72-1.34c1.71,0.12,3.24,0.83,4.6,2.13
                                c1.53,1.45,2.32,2.98,2.39,4.6c0.06,1.61-0.61,3.15-2.02,4.63c-3.3,3.47-7.44,3.44-12.4-0.08c-0.68-0.46-1.14-0.57-1.36-0.33
                                c-0.28,0.29-0.24,0.61,0.13,0.96c0.24,0.23,0.45,0.4,0.62,0.52c1.16,1.02,1.87,1.64,2.11,1.87c1.26,1.2,2.54,2.6,3.83,4.2
                                c0.07,0.12,0.21,0.27,0.4,0.45c0.29,0.28,0.55,0.3,0.78,0.05c0.28-0.29,0.25-0.61-0.07-0.97c-0.6-0.71-0.76-1.21-0.49-1.51
                                c0.23-0.24,0.68-0.5,1.37-0.78c2.53-1.06,4.42-2.24,5.67-3.55c2.12-2.23,3.21-4.84,3.25-7.83c0.04-2.99-0.97-5.47-3.06-7.46
                                c-1.5-1.43-3.32-2.21-5.44-2.34c-2.12-0.13-4.84,0.41-8.15,1.61c-2.81,1.02-4.77,1.56-5.86,1.64c-1.94,0.14-3.68-0.53-5.23-2.01
                                c-1.33-1.27-2.05-2.63-2.15-4.09c-0.1-1.45,0.43-2.79,1.59-4c1.18-1.24,2.72-1.89,4.63-1.98c1.91-0.08,3.7,0.42,5.39,1.52
                                c0.65,0.43,1.1,0.52,1.35,0.25c0.23-0.24,0.2-0.48-0.09-0.71l-0.62-0.52c-0.79-0.52-1.78-1.34-2.94-2.45
                                c-0.82-0.78-1.5-1.49-2.01-2.12c-0.07-0.11-0.21-0.26-0.4-0.45c-0.29-0.28-0.55-0.29-0.78-0.05c-0.21,0.22-0.22,0.47-0.02,0.75
                                c0.34,0.46,0.52,0.78,0.54,0.96c0.01,0.18-0.11,0.4-0.36,0.66C105.78,108.65,105.33,108.94,104.65,109.26"/>
                            <path d="M133.95,84.45c1.44-0.77,2.79-0.94,4.03-0.52c0.94,0.32,1.88,0.88,2.82,1.67c0.43,0.43,0.86,0.86,1.29,1.29
                                c0.68,0.57,1.17,0.73,1.48,0.48c0.32-0.25,0.34-0.54,0.06-0.89c-0.02-0.03-0.09-0.09-0.21-0.18c-0.12-0.1-0.21-0.18-0.28-0.27
                                c-2.59-2.64-4.5-4.67-5.73-6.1L131,85.27c-1.97,1.62-3.43,2.79-4.39,3.52l-8.09,6.29l-1.76,1.41c1.42,2.09,2.57,3.76,3.45,5.03
                                l0.64,0.96c0.21,0.31,0.42,0.61,0.65,0.89c0.44,0.55,0.82,0.7,1.13,0.45c0.29-0.23,0.16-0.77-0.39-1.62
                                c-1.81-2.84-2.13-5.23-0.97-7.15c0.44-0.74,1.94-2.16,4.51-4.26l18.85,23.51c0.7,0.86,1.06,1.45,1.1,1.77
                                c0.04,0.53-0.35,1.12-1.16,1.77c-1.1,0.93-1.56,1.51-1.37,1.74c0.17,0.21,0.55,0.08,1.16-0.41c1.17-0.94,2.13-1.74,2.85-2.39
                                c0.72-0.65,1.52-1.31,2.38-2.01c0.65-0.53,1.78-1.39,3.39-2.6c0.18-0.1,0.37-0.23,0.58-0.4c0.42-0.33,0.54-0.61,0.36-0.83
                                c-0.16-0.2-0.4-0.18-0.74,0.04c-1.22,0.94-2.02,1.45-2.38,1.52c-0.69,0.16-1.41-0.23-2.16-1.17L129.66,87.7
                                c0.25-0.23,0.97-0.85,2.17-1.87C132.31,85.4,133.01,84.94,133.95,84.45"/>
                            <path d="M167.67,101.36c0.72,0.16,1.41,0.04,2.05-0.38c0.62-0.39,1.01-0.95,1.17-1.67c0.16-0.72,0.03-1.4-0.37-2.02
                                c-0.42-0.65-0.98-1.06-1.71-1.23c-0.72-0.16-1.39-0.05-2.01,0.35c-0.65,0.42-1.05,0.98-1.21,1.7c-0.16,0.72-0.03,1.39,0.37,2.02
                                C166.39,100.79,166.95,101.2,167.67,101.36"/>
                            <path d="M199.26,83.58c-0.75,0.36-1.47,0.69-2.15,0.98c-0.43,0.19-0.59,0.42-0.47,0.69c0.12,0.28,0.55,0.26,1.28-0.06
                                c0.34-0.15,0.8-0.34,1.38-0.59c0.59-0.25,1.93-0.84,4.05-1.75c1.19-0.52,2.38-1.03,3.54-1.53l1.75-0.75l0.55-0.24
                                c0.67-0.29,0.96-0.57,0.83-0.85c-0.13-0.31-0.42-0.37-0.84-0.18l-0.69,0.3l-0.78,0.34l-1.06,0.46c-0.66,0.25-1.08,0.3-1.27,0.16
                                c-0.19-0.14-0.52-0.76-1-1.87l-11.91-27.58c-0.13-0.31-0.25-0.59-0.36-0.83l-0.38-0.88c-0.13-0.31-0.32-0.41-0.57-0.3
                                c-0.12,0.05-0.29,0.29-0.51,0.71c-0.46,1.37-1.27,2.73-2.43,4.09c-1.16,1.36-2.42,2.42-3.79,3.2c-0.36,0.19-0.48,0.41-0.37,0.65
                                c0.09,0.22,0.32,0.24,0.7,0.08c0.12-0.05,0.37-0.18,0.73-0.37c1.8-0.96,2.77-1.47,2.93-1.54c0.37-0.16,0.63-0.16,0.77-0.01
                                c0.14,0.16,0.43,0.73,0.85,1.71l9.35,21.64c0.63,1.47,1.01,2.45,1.13,2.93C200.61,82.68,200.2,83.14,199.26,83.58"/>
                            <path d="M224.01,75.05c-0.43,0.19-0.62,0.39-0.57,0.58c0.06,0.22,0.52,0.22,1.35-0.02c2.32-0.66,4.5-1.8,6.55-3.42
                                c2.78-2.22,4.66-5.32,5.64-9.3c0.98-3.98,0.87-8.09-0.33-12.31c-1.24-4.38-3.29-7.66-6.16-9.85c-2.87-2.18-5.94-2.81-9.23-1.87
                                c-2.68,0.76-4.65,2.49-5.92,5.2c-1.27,2.71-1.46,5.61-0.59,8.7c1.03,3.64,3.09,6.04,6.16,7.18c2.11,0.79,4.22,0.89,6.35,0.29
                                c2.22-0.63,3.98-2.03,5.28-4.21c0.51,3.02,0.63,5.6,0.36,7.73C232.23,69.03,229.25,72.8,224.01,75.05 M231.05,55.49
                                c-0.51,1.76-1.59,2.88-3.23,3.34c-3.71,1.05-6.41-1.41-8.09-7.37c-1.97-6.96-1.3-10.91,2.02-11.85c1.61-0.46,3.09-0.09,4.43,1.09
                                c1.96,1.74,3.46,4.45,4.49,8.12C231.44,51.51,231.56,53.72,231.05,55.49"/>
                            <path d="M266.96,38.02c-0.31-2.16-1.41-3.92-3.31-5.31c-1.89-1.38-4.41-1.85-7.56-1.39c-2.75,0.4-4.81,1.31-6.17,2.75
                                c-1.36,1.43-1.92,3.04-1.66,4.84c0.16,1.09,0.63,1.92,1.4,2.49c0.78,0.56,1.73,0.76,2.85,0.6c1.76-0.25,2.51-1.23,2.27-2.92
                                c-0.19-1.29-1.41-2.05-3.68-2.26c-0.48-0.03-0.74-0.2-0.78-0.5c-0.11-0.76,0.3-1.51,1.23-2.23c0.93-0.73,2.07-1.19,3.43-1.39
                                c1.86-0.27,3.42,0.15,4.7,1.27c1.28,1.11,2.07,2.73,2.38,4.84c0.31,2.15-0.06,4-1.12,5.55c-1.06,1.56-2.73,2.66-5,3.33
                                c-0.78,0.24-1.15,0.49-1.12,0.72c0.04,0.3,0.28,0.41,0.71,0.35l0.35-0.05c0.23-0.03,0.59-0.09,1.09-0.16
                                c5.04-0.73,7.96,1.71,8.78,7.33c0.4,2.79-0.06,5.16-1.38,7.12c-1.32,1.96-3.25,3.12-5.76,3.48c-1.03,0.15-2,0.11-2.91-0.12
                                c-0.91-0.23-1.4-0.55-1.46-0.99c-0.04-0.27,0.24-0.58,0.83-0.94c1.46-0.89,2.11-1.92,1.94-3.09c-0.11-0.77-0.49-1.36-1.12-1.78
                                c-0.63-0.42-1.38-0.57-2.24-0.44c-1.09,0.16-1.92,0.59-2.48,1.3c-0.56,0.71-0.76,1.57-0.61,2.6c0.17,1.19,0.79,2.19,1.85,2.98
                                c1.67,1.25,4.06,1.65,7.18,1.2c3.81-0.55,6.79-2.09,8.93-4.6c1.94-2.28,2.71-4.79,2.31-7.54c-0.46-3.15-2.05-5.37-4.78-6.67
                                c-1.85-0.88-4.16-1.17-6.93-0.88l-0.05-0.35c2.17-0.65,3.72-1.3,4.64-1.94C266.3,43.42,267.39,41.01,266.96,38.02"/>
                            <path d="M292.18,32.28c2.29-1.93,4.94-3.05,7.95-3.35c0.47-0.04,0.7-0.19,0.7-0.44c0-0.28-0.36-0.42-1.06-0.41
                                c-3.02,0.04-5.79,0.72-8.31,2.06c-3.03,1.61-5.44,4.15-7.22,7.63c-1.78,3.47-2.65,7.3-2.6,11.49c0.05,4.56,1.17,8.24,3.35,11.06
                                c2.18,2.82,4.99,4.21,8.44,4.17c2.75-0.03,5.09-1.22,7.04-3.55c1.95-2.33,2.9-5.13,2.86-8.37c-0.05-4.02-1.66-6.97-4.83-8.84
                                c-1.65-0.98-3.42-1.46-5.29-1.44c-2.31,0.03-4.38,0.92-6.2,2.69C287.47,39.02,289.2,34.79,292.18,32.28 M288.28,45.78
                                c0.92-1.51,2.25-2.27,3.99-2.29c3.85-0.05,5.81,3.05,5.89,9.3c0.09,7.19-1.65,10.8-5.19,10.84c-1.68,0.02-2.96-0.72-3.85-2.22
                                c-1.4-2.37-2.13-5.48-2.17-9.35C286.91,49.39,287.36,47.29,288.28,45.78"/>
                            <polygon points="154.62,477.98 143.85,401.97 132.64,407.95 141.24,462.85 100.4,425.17 89.39,431.04 146.36,482.39 	"/>
                            <rect x="174.68" y="387.12" transform="matrix(0.8822 -0.4709 0.4709 0.8822 -177.9987 134.9568)" width="12.03" height="72.13"/>
                            <polygon points="278.88,411.65 244.91,348.02 234.29,353.69 258.15,398.37 201.95,370.95 192.22,376.15 226.19,439.78 
                                236.8,434.11 212.9,389.34 269.15,416.85 	"/>
                            <polygon points="322.38,341.69 293.28,357.23 283.35,338.64 317.47,320.43 312.84,311.76 268.11,335.64 302.08,399.27 
                                346.81,375.39 342.18,366.72 308.07,384.93 297.86,365.81 326.95,350.27 	"/>
                            <path d="M359.25,297.43c7.87-4.2,15.17-2.82,20.66,3.21l10.72-5.72c-7.79-9.85-21.16-14.08-36.01-6.16
                                c-7.47,3.99-12.28,9.66-14.75,16.61c-3.55,9.93,0.32,16.94,6.86,29.18c6.54,12.24,10.21,19.35,20.43,21.94
                                c7.16,1.81,14.73,0.86,22.01-3.02c7.38-3.94,12.82-9.59,15.41-17.52c2.23-6.94,1.09-13.1-3.3-21.32l-5.2-9.74l-25.56,13.64
                                l4.39,8.22l14.94-7.97l1.91,3.57c2.2,4.11,2.93,7.85,1.8,11.9c-1.22,4.33-4.39,8.09-9.01,10.55c-4.13,2.21-8.63,2.77-12.38,1.67
                                c-5.25-1.56-7.99-4.8-14.82-17.58c-6.82-12.78-7.95-16.77-6.32-22.01C352.2,303.15,355.13,299.64,359.25,297.43"/>
                            <path d="M425.65,333.3l11.3-6.03l-2.01-15.35l27.62-14.75l11.64,10.21l11.31-6.04l-59.63-49.93l-8.55,4.56L425.65,333.3z
                                M455.23,290.53l-21.53,11.49l-4.16-34.07L455.23,290.53z"/>
                            <path d="M510.98,287.76l-14.31-26.81l13.27-7.08l29.06,18.94l12.39-6.61l-31.15-19.54c6.82-6.17,9.81-15.23,4.94-24.35
                                c-5.77-10.81-18.38-14.08-31.64-6.99l-27.13,14.49l33.97,63.63L510.98,287.76z M481.64,232.79l15.73-8.4
                                c7.08-3.78,13.9-2.6,17.24,3.66c3.34,6.25,0.48,12.49-6.6,16.27l-15.73,8.4L481.64,232.79z"/>
                            <polygon points="286.88,485.03 274.25,425.92 265.23,430.74 274.96,472.62 244.09,442.03 237.2,445.7 245.43,488.38 216.06,456.99 
                                207.02,461.81 249.11,505.19 256.55,501.22 248.78,459.22 279.44,489 	"/>
                            <path d="M319.06,408.48c-5.76-1.46-11.79-0.73-17.8,2.48c-6.02,3.21-9.89,7.78-11.89,13.37c-2.85,8,0.26,13.64,5.52,23.5
                                c5.26,9.86,8.22,15.59,16.45,17.67c5.76,1.45,11.71,0.77,17.73-2.44c6.02-3.21,9.97-7.82,11.97-13.41c2.85-8-0.34-13.6-5.6-23.46
                                C330.17,416.33,327.29,410.56,319.06,408.48 M332.05,448.43c-0.94,3-3.38,5.87-6.7,7.65c-3.33,1.77-7.07,2.2-10.08,1.31
                                c-4.23-1.25-6.33-3.84-11.82-14.13c-5.49-10.29-6.48-13.47-5.16-17.68c0.94-3,3.38-5.87,6.7-7.65c3.33-1.78,7.07-2.2,10.09-1.31
                                c4.23,1.26,6.32,3.84,11.82,14.13C332.38,441.04,333.36,444.22,332.05,448.43"/>
                            <path d="M394.48,427.59l9.98-5.32l-25.09-15.74c5.49-4.97,7.9-12.26,3.98-19.61c-4.65-8.71-14.8-11.34-25.49-5.63l-21.86,11.67
                                l27.36,51.24l8.55-4.56l-11.53-21.59l10.69-5.71L394.48,427.59z M356.85,411.42l-8.57-16.05l12.67-6.76
                                c5.7-3.04,11.2-2.09,13.89,2.95c2.69,5.04,0.38,10.06-5.32,13.1L356.85,411.42z"/>
                            <polygon points="421.04,347.56 411.76,385.63 398,359.86 389.45,364.43 416.81,415.67 425.36,411.11 417.41,396.21 421.37,380.77 
                                450.7,397.58 460.76,392.21 423.92,371.83 431.5,341.98 	"/>
                            <path d="M462.03,333.82c4.75-2.54,9.29-3.76,14.39-2.6l2.85-7.81c-7.02-1.52-13.17-0.46-20.62,3.51
                                c-11.72,6.26-15.58,16.18-10.82,25.1c2.23,4.18,5.31,6.69,9.35,7.59c3.56,0.78,7.98,0.27,12.88-1.61l7.11-2.69
                                c3.56-1.35,5.25-1.6,7.33-1.14c2.16,0.42,3.87,1.91,5.06,4.14c2.65,4.97-0.09,10.13-7.45,14.06c-5.78,3.09-10.91,4.35-17.03,2.8
                                l-2.97,8.15c8.03,2.09,15.09,0.64,23.57-3.89c11.79-6.3,17.27-16.43,12.24-25.86c-2.35-4.39-5.81-7.26-10.21-8.06
                                c-3.41-0.67-6.96-0.26-12.57,1.81l-6.87,2.56c-2.81,1.03-5.56,1.4-7.61,1c-2.16-0.42-3.67-1.73-4.67-3.61
                                C453.58,342.77,455.38,337.37,462.03,333.82"/>
                            <path d="M130.09,344.1c-4.59,10.59-12.07,18.65-22.44,24.2c-8.43,4.5-17.29,6.32-26.59,5.47c-9.3-0.86-17.93-4.02-25.9-9.49
                                c-7.97-5.47-14.34-12.68-19.12-21.62c-4.62-8.66-7.07-17.76-7.34-27.3c-0.27-9.54,1.75-18.36,6.05-26.44
                                c4.31-8.08,10.73-14.4,19.27-18.97c8.83-4.71,18.18-6.72,28.06-6.03c9.42,0.66,18.29,3.76,26.62,9.29c0.75,0.5,1.71,0.56,2.51,0.14
                                l13.39-7.15c1.2-0.64,1.65-2.14,1.01-3.34l-19.85-37.19c-0.64-1.2-2.14-1.66-3.34-1.01l-12.16,6.49c-1.2,0.64-1.65,2.13-1.01,3.34
                                l6.46,12.1c-8.87-2.12-17.6-2.68-26.21-1.7c-8.61,0.99-17.13,3.73-25.55,8.23c-12.56,6.7-22.2,15.48-28.93,26.33
                                c-6.73,10.85-10.12,22.88-10.17,36.1c-0.06,13.22,3.43,26.39,10.44,39.52c6.46,12.1,15.1,21.76,25.92,28.98
                                c10.82,7.23,22.7,11.23,35.65,12.02c12.95,0.79,25.69-2.17,38.25-8.88c15.25-8.14,26.42-19.21,33.5-33.19
                                c6.79-13.41,8.84-28.14,6.13-44.17c-0.2-1.19-1.25-2.06-2.46-2.05l-16.21,0.03c-1.52,0-2.65,1.37-2.42,2.88
                                C135.54,323.12,134.36,334.26,130.09,344.1"/>
                            <path d="M246.56,226.33c-8.25-5.65-17.04-8.7-26.37-9.13c-9.33-0.43-18.76,1.89-28.27,6.97c-8.71,4.65-15.59,10.96-20.62,18.92
                                c-5.03,7.96-7.69,16.68-7.96,26.18c-0.27,9.5,2.01,18.78,6.85,27.84c4.87,9.12,11.35,16.19,19.45,21.22
                                c8.1,5.03,17.02,7.56,26.76,7.59c9.74,0.03,19.49-2.56,29.23-7.76c9.4-5.02,17.05-11.63,22.96-19.83
                                c5.34-7.41,8.6-15.69,9.78-24.84c0.2-1.52-1.1-2.83-2.63-2.73l-14.47,0.97c-1.17,0.08-2.09,0.98-2.27,2.14
                                c-0.92,6.11-3.08,11.5-6.5,16.19c-3.79,5.19-8.66,9.38-14.62,12.56c-8.14,4.35-16.36,5.51-24.66,3.49
                                c-8.3-2.01-15.3-7.06-21.01-15.14l73.47-39.23c1.25-0.67,1.7-2.26,0.96-3.47C260.91,238.89,254.21,231.57,246.56,226.33
                                M185.94,277.98c-2.52-8.16-2.45-15.84,0.19-23.03c2.64-7.19,7.32-12.58,14.02-16.16c7.05-3.77,14.47-4.83,22.27-3.21
                                c7.79,1.62,14.49,5.69,20.11,12.2L185.94,277.98z"/>
                            <path d="M346.36,243.49c-8.26,4.41-16.51,4.96-24.75,1.66c-8.24-3.29-14.89-9.67-19.94-19.13c-2.97-5.56-4.59-11.29-4.87-17.18
                                c-0.27-5.9,0.9-11.31,3.53-16.25c2.63-4.94,6.52-8.79,11.68-11.54c6.02-3.22,12.25-4.31,18.69-3.29
                                c5.81,0.92,10.71,3.96,14.71,9.13c0.75,0.98,2.07,1.31,3.16,0.73l11.75-6.27c1.2-0.64,1.65-2.14,1.01-3.34l-15.95-29.87
                                c-0.64-1.2-2.14-1.65-3.34-1.01l-11.47,6.12c-1.2,0.64-1.65,2.14-1.01,3.34l2.33,4.36c-10.24-2.2-20.28-0.66-30.14,4.6
                                c-8.31,4.44-14.77,10.49-19.38,18.14c-4.61,7.66-6.91,16.13-6.93,25.42c-0.01,9.29,2.34,18.35,7.05,27.18
                                c4.81,9,11.09,16.18,18.85,21.54c7.76,5.36,16.27,8.29,25.53,8.8c9.26,0.51,18.39-1.64,27.39-6.45
                                c19.37-10.34,28.82-26.1,28.34-47.28c-0.03-1.37-1.22-2.44-2.59-2.38l-13.65,0.62c-1.33,0.06-2.33,1.19-2.3,2.52
                                C364.33,227.09,358.43,237.04,346.36,243.49"/>
                            <path d="M378.07,102.56c2.92-1.56,4.88-3.97,5.87-7.22c0.99-3.25,0.7-6.34-0.86-9.27c-1.62-3.04-4.02-5.04-7.18-6
                                c-3.17-0.96-6.27-0.63-9.31,0.99c-2.92,1.56-4.85,3.95-5.78,7.18c-0.93,3.22-0.62,6.3,0.95,9.22c1.56,2.92,3.94,4.9,7.14,5.91
                                C372.08,104.39,375.15,104.12,378.07,102.56"/>
                            <path d="M396.13,118.23l-27.55,14.71c-1.2,0.64-1.65,2.13-1.01,3.34l6.17,11.56c0.64,1.2,2.14,1.66,3.34,1.01l11.77-6.29
                                c0.09-0.05,0.19-0.01,0.24,0.07l31.23,58.49l-11.93,6.37c-1.2,0.64-1.65,2.14-1.01,3.34l6.13,11.47c0.64,1.2,2.14,1.66,3.34,1.01
                                l41.31-22.06c1.2-0.64,1.66-2.13,1.01-3.34l-6.13-11.47c-0.64-1.2-2.14-1.65-3.34-1.01l-11.15,5.95c-0.24,0.13-0.54,0.04-0.67-0.2
                                l-38.42-71.95C398.82,118.04,397.33,117.59,396.13,118.23"/>
                            <path d="M503.36,156.54L443.87,45.14c-0.64-1.2-2.14-1.65-3.34-1.01l-29.44,15.72c-1.2,0.64-1.66,2.14-1.01,3.34l6.03,11.3
                                c0.64,1.2,2.14,1.66,3.34,1.01l13.71-7.32c0.06-0.03,0.13-0.01,0.16,0.05l52.34,98.02l-13.82,7.38c-1.2,0.64-1.65,2.14-1.01,3.34
                                l6.13,11.47c0.64,1.2,2.14,1.66,3.34,1.01l43.21-23.07c1.2-0.64,1.65-2.13,1.01-3.34l-6.13-11.47c-0.64-1.2-2.14-1.65-3.34-1.01
                                l-11.39,6.08C503.54,156.69,503.41,156.65,503.36,156.54"/>
                            <path d="M472.49,48.9v-0.12c0-11.36,9.2-20.92,20.8-20.92c11.72,0,20.8,9.44,20.8,20.8v0.12c0,11.36-9.2,20.92-20.8,20.92
                                C481.57,69.7,472.49,60.26,472.49,48.9z M511.69,48.78v-0.12c0-10.16-7.89-18.53-18.41-18.53c-10.4,0-18.41,8.49-18.41,18.65v0.12
                                c0,10.16,7.89,18.53,18.41,18.53C503.69,67.43,511.69,58.94,511.69,48.78z M485.16,37.79h9.56c4.66,0,8.13,2.27,8.13,6.69
                                c0,3.47-2.03,5.62-4.9,6.46l5.62,8.01h-4.9l-5.02-7.29h-4.42v7.29h-4.07V37.79z M494.36,48.31c2.75,0,4.3-1.43,4.3-3.47
                                c0-2.27-1.55-3.47-4.3-3.47h-5.14v6.93H494.36z"/>
                        </g>
                        </svg>

                    </Link>
                </li>
            </ul>
        )
    }
}

export default NavLogo