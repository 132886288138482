import React from 'react'
import {Link} from 'react-router-dom'

class NavLinks extends React.Component{
    render(){
        return(
            <ul>
                <li>
                    <Link to="/">HOME</Link>
                </li>
                <li>
                    <Link to="/products">PRODUCTS</Link>
                </li>
                <li>
                    <Link to="/about-us">ABOUT US</Link>
                </li>
                <li>
                    <Link to="/about-vinegar">ABOUT VINEGAR</Link>
                </li>
                <li>
                    <a href="https://safarivinegar.co.za/recipes/" target="_blank" rel="noreferrer">RECIPES</a>
                </li>
                <li>
                    <a href="https://safarivinegar.co.za/how-to-body/" target="_blank" rel="noreferrer">WELLNESS</a>
                </li>
                <li>
                    <Link to="/retail">RETAIL</Link>
                </li>
                <li>
                    <Link to="/exports">EXPORTS</Link>
                </li>
                <li>
                    <Link to="/industrial-and-food-services">INDUSTRIAL & FOOD SERVICES</Link>
                </li>
                <li>
                    <Link to="/contact">CONTACT US</Link>
                </li>
            </ul>
        )
    }
}

export default NavLinks