import _ from 'lodash'
import {
    FETCH_RANGES_BIN,
    RESTORE_RANGE,
    FORCE_DELETE_RANGE
} from '../actions/types'

const initialState = {}

const rangesBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_RANGES_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_RANGE:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_RANGE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default rangesBinReducer