import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import CollectionSection from './ui/CollectionSection'
import ExploreMoreProducts from './views/ExploreMoreProducts'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchPublicPageExplore,
    fetchPublicPageBrandBanner,
    fetchPublicPageProduct
} from '../actions'
import {BASE_PATH} from '../paths'
import Helmet from 'react-helmet'

import placeholder from '../assets/img/placeholders/hero.jpg'

class Product extends React.Component {

    state = {
        tab1: false,
        tab2: true
    }

    componentDidMount(){
        this.props.fetchPublicPageExplore(this.props.match.params.range)
        this.props.fetchPublicPageBrandBanner(this.props.match.params.range)
        this.props.fetchPublicPageProduct(this.props.match.params.slug)
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.range !== prevProps.match.params.range){
            this.props.fetchPublicPageExplore(this.props.match.params.range)
            this.props.fetchPublicPageBrandBanner(this.props.match.params.range)
        }
        if(this.props.match.params.slug !== prevProps.match.params.slug){
            this.props.fetchPublicPageProduct(this.props.match.params.slug)
        }
    }

    onSwitchTab = index => {
        switch (index) {
            case 1:
                this.setState({
                    tab1: true,
                    tab2: false
                })
                break;
            case 2:
                this.setState({
                    tab1: false,
                    tab2: true
                })
                break;
            default:
                break;
        }
    }

    renderRecipiesButton = () => {
        switch (this.props.data.brandSlug) {
            case 'the-verjuice-co':
                return <a href="https://taste.co.za/?s=verjuice" className="_button _button-3" target="_blank" rel="noreferrer">View Recipies</a>
            default:
                return <Link to="/recipes" className="_button _button-3">View Recipes</Link>
        }
    }

    renderMainContent = () => {
        const {
            // benifits,
            brandSlug,
            description,
            image,
            // ingredients,
            name, 
            range
        } = this.props.data

        return (
            <section className='_section _section-single-product'>
                <div className='_grid _grid-3'>
                    <div className='_column'>
                        <Link to={`/products/${brandSlug}`} className="_button _button-2">{range}</Link>
                        <h2>{name}</h2>
                        {/* <div className='_copy' dangerouslySetInnerHTML={{__html: description}} /> */}
                        <br />
                        <br />
                        <div className='_hr' />
                        <h4>INSPIRING RECIPES FOR YOU!</h4>
                        {this.renderRecipiesButton()}
                    </div>
                    <div className='_column'>
                        <div className='_product-image'>
                            <img src={BASE_PATH+image} alt="" />
                        </div>
                    </div>
                    <div className='_column'>
                        <div className='_hr' /><br />
                        <div className='_description' dangerouslySetInnerHTML={{__html: description}} />
                        {/* <div className='_panel'>
                            <div className='_panel-item'>
                                <div className='_tabs'>
                                    <div className={`_tab ${this.state.tab1 && '_active'}`} onClick={() => this.onSwitchTab(1)}><h3>BENEFITS</h3></div>
                                    <div className='_tab' ><h3>|</h3></div>
                                    <div className={`_tab ${this.state.tab2 && '_active'}`} onClick={() => this.onSwitchTab(2)}><h3>INGREDIENTS</h3></div>
                                </div>
                                <div className='_hr' />
                                <div className='_displays'>
                                    {
                                        this.state.tab1 && (
                                            <div className='_display' dangerouslySetInnerHTML={{__html:benifits}} />
                                        )
                                    }
                                    {
                                        this.state.tab2 && (
                                            <div className='_display' dangerouslySetInnerHTML={{__html:ingredients}} />
                                        )
                                    }
                                </div>
                            </div>
                            <div className='_panel-item'>
                                <div className='_get-in-touch'>
                                    <h3>If you want to buy now get in touch with us.</h3>
                                    <Link to="/contact" className='_button _button-1'>Contact Us</Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        )
    }

    toCapatalize(str) {
        return str.split(' ').map(function(word){
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
      }

    render(){

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>{this.props.data ? `${this.toCapatalize(this.props.data.name)} | ` : "" } {this.props.banner ? this.props.banner.name : "Products"} | Cecil Vinegar Works</title>
                    <meta name="description" content={this.props.data ? this.props.data.description.replace(/(<([^>]+)>)/gi, "") : "We have a range of different products ranging from those that are locally produced, to those that are imported, and of course our speciality range for the most discerning of buyers."} />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <PageHeaderSection 
                    img={this.props.banner ? BASE_PATH+this.props.banner.banner_image : placeholder}
                >
                    {this.props.banner && <h1>{this.props.banner.name}</h1>}
                    {this.props.banner && (
                        <div dangerouslySetInnerHTML={{__html: this.props.banner.summary}} />
                    )}
                </PageHeaderSection>

                {this.props.data && this.renderMainContent()}
                {this.props.data && <CollectionSection data={this.props.data.related} />}
                {this.props.explore && <ExploreMoreProducts data={this.props.explore} />}
                <PreFooterSection />
            </Layout>
            
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        explore: state.publicExploreState[ownProps.match.params.range],
        banner: state.publicBannerState[ownProps.match.params.range],
        data: state.publicProductState[ownProps.match.params.slug]
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageExplore,
    fetchPublicPageBrandBanner,
    fetchPublicPageProduct
})(Product)