import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from './fields'

class AuthForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){
        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Enter Email / Username" placeholder="Enter Email / Username" />
                </div>
                <div className="_form-row">
                    <Field name="password" type="password" component={Input} label="Enter Password" placeholder="Enter Password" />
                </div>
                <div className="_form-group">
                    <button className='_button _button-2'>Sign In</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter your username / email"
    }

    if(!formValues.password){
        errors.password = "You must enter your password"
    }

    return errors
}

export default reduxForm({
    form: 'auth',
    validate
})(AuthForm)