import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import BrandLogos from './views/BrandLogos'
import Brands from './views/Brands'
import Helmet from 'react-helmet'

//images
import banner from '../assets/img/products/products-banner.jpg'

class Products extends React.Component {

    render(){

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>Products | Cecil Vinegar Works</title>
                    <meta name="description" content="We have a range of different products ranging from those that are locally produced, to those that are imported, and of course our speciality range for the most discerning of buyers." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <PageHeaderSection 
                    img={banner}
                >
                    <h1>PRODUCTS</h1>
                    <p>We have a range of different products ranging from those that are locally produced, to those that are imported, and of course our speciality range for the most discerning of buyers.</p>
                </PageHeaderSection>
                <BrandLogos />
                <Brands />
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default Products