import _ from 'lodash'
import {
    FETCH_RECIPES,
    CREATE_RECIPE,
    FETCH_RECIPE,
    UPDATE_RECIPE,
    DELETE_RECIPE,
    RESTORE_RECIPE
} from '../actions/types'

const initialState = {}

const recipesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_RECIPES:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE_RECIPE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH_RECIPE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE_RECIPE:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE_RECIPE:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE_RECIPE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default recipesReducer