import _ from 'lodash'
import {
    FETCH_RECIPES_BIN,
    RESTORE_RECIPE,
    FORCE_DELETE_RECIPE
} from '../actions/types'

const initialState = {}

const recipesBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_RECIPES_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_RECIPE:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_RECIPE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default recipesBinReducer