import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/industrial-and-food/industrial-banner.jpg'
import img1 from '../assets/img/industrial-and-food/Tanks/flow bin@2x.png'
import img2 from '../assets/img/industrial-and-food/Tanks/bulk-tanker@2x.png'
import img3 from '../assets/img/industrial-and-food/Tanks/export-tanker@2x-2.png'
// import product1 from '../assets/img/industrial-and-food/Apple-Cider-Vinegar@2x.png'
// import product2 from '../assets/img/industrial-and-food/Grape-Vinegar@2x.png'
// import product3 from '../assets/img/industrial-and-food/Speciality-Vinegars@2x.png'
// import placeholder from '../assets/img/industrial-and-food/replacement@2x.png'

class IndustrialAndFood extends React.Component {

    renderItems = data => {
        return data.map(({img,title,acidity,packSizes}, index) => {
            return (
                <div className='_column _item'>
                    {/* <img src={img} alt="" /> */}
                    <h4 className='_section-heading'>{title}</h4>
                    <div className='_hr' />
                    <p>
                        <strong>Acidity Strength:</strong> <br />
                        {acidity} 
                    </p>
                    <p style={{marginBottom: '0'}}>
                        <strong>Pack Sizes:</strong> 
                        <br />
                        {packSizes}
                    </p>
                </div>
            )
        })
    }

    render(){

        const data = [
            {
                // img: product1,
                title: 'Apple Cider Vinegar',
                acidity: '5% | 6%',
                packSizes: '5 litre | 25 litre | 1 000 litre | 5000 – 25000 litre Bulk tanker loads'
            },
            {
                // img: placeholder,
                title: 'Spirit Vinegar',
                acidity: '5% | 10% | 17%',
                packSizes: '5 litre | 25 litre | 1 000 litre | 5000 – 25000 litre Bulk tanker loads'
            },
            {
                // img: product2,
                title: 'Grape Vinegar',
                acidity: '5% | 10%',
                packSizes: '5 litre | 25 litre | 1 000 litre | 5000 – 25000 litre Bulk tanker loads'
            },
            {
                // img: placeholder,
                title: 'Wine vinegar (Red & White)',
                acidity: '6%',
                packSizes: '5 litre | 25 litre | 1000 litre | 5000 – 25000 litre Bulk tanker loads'
            },
            {
                // img: placeholder,
                title: 'Balsamic',
                acidity: '6%',
                packSizes: '5 litre | 25 litre | 1000 litre'
            },
            {
                // img: product3,
                title: 'Speciality Vinegars (Rice, Malt & Sherry)',
                acidity: '6%',
                packSizes: '5 litre | 25 litre | 1000 litre'
            }
        ]

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>Industrial & Food Services | Cecil Vinegar Works</title>
                    <meta name="description" content="Our vinegars are available in larger offerings not found in retail outlets and are mainly used as food ingredients, but also for packing under own brands. Our higher strength spirit vinegars are known to also be used as an environmentally friendly alternative for cleaning heavy duty industrial equipment." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <PageHeaderSection img={banner}>
                    <h1>INDUSTRIAL & FOOD SERVICES  </h1>
                </PageHeaderSection>

                <section className='_section _section-food-and-industry' style={{paddingBottom: '0'}}>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_section-heading'>Catering & Industrial Offerings</h2>
                                <p style={{marginBottom: '0'}}>Our vinegars are available in larger offerings not found in retail outlets and are mainly used as food ingredients, but also for packing under own brands. Our higher strength spirit vinegars are known to also be used as an environmentally friendly alternative for cleaning heavy duty industrial equipment.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='_section _section-food-and-industry' style={{paddingBottom: '0'}}>
                    <div className='_grid' style={{marginBottom: '30px'}}>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_section-heading'>Acidity Strength’s & Pack Sizes</h2>
                            </div>
                        </div>
                    </div>
                    <div className='_grid _grid-3'>
                        {data && this.renderItems(data)}
                    </div>
                </section>
                <section className='_section _section-food-and-industry'>
                    <div className='_grid' style={{marginBottom: '60px'}}>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_section-heading' style={{marginTop: '0',marginBottom: '30px'}}>For Larger Quantities</h2>
                                <p style={{marginBottom: '0'}}>220 litre Drums and 1000 litre IBC. Road Tankers and Tank Container</p>
                            </div>
                        </div>
                    </div>
                    <div className='_grid _grid-3'>
                        <div className='_column'>
                            <img src={img1} alt="" />
                            <h3 style={{marginTop: '30px'}}>ISO Tank/Flow Bin</h3>
                            <p style={{marginBottom: '0'}}>1000 litre</p>
                        </div>
                        <div className='_column'>
                            <img src={img2} alt="" />
                            <h3 style={{marginTop: '30px'}}>Bulk Tanker Loads</h3>
                            <p style={{marginBottom: '0'}}>5000 litre to 30 000 litre loads</p>
                        </div>
                        <div className='_column'>
                            <img src={img3} alt="" />
                            <h3 style={{marginTop: '30px'}}>Export Tanktainer or ISO Tanker</h3>
                            <p style={{marginBottom: '0'}}>24000 litres nett fill</p>
                        </div>
                    </div>
                </section>
                <section className='_section _section-food-services'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h2 className='_section-heading'>Speciality Products Regional Distributors</h2>
                        </div>
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <div className='_panel-item'>
                                    <h3>Gauteng & Surrounding Areas</h3>
                                    <div className='_hr' />
                                    <h4>Aftex Distributors</h4>
                                    <p>
                                        <strong>Website:</strong> <a href="https://www.aftex.co.za" target="_blank" rel="noreferrer">www.aftex.co.za</a> <br />
                                        <strong>Address:</strong> 419 West Avenue, Unit 1 Aspen Place, Ferndale, Randburg, 2194 South Africa <br />
                                        <strong>Tel:</strong> +27 11 792 3544
                                    </p>
                                </div>
                                <div className='_panel-item'>
                                    <h3>KZN & Surrounding Areas</h3>
                                    <div className='_hr' />
                                    <h4>Klooffern Agencies</h4>
                                    <p>
                                        <strong>Website:</strong> <a href="http://www.klooffernagencies.co.za" target="_blank" rel="noreferrer">www.klooffernagencies.co.za</a> <br />
                                        <strong>Tel:</strong> 031 764 1952 | 083 357 8344 <br />
                                        <strong>Email:</strong> <a href="mailto:klooffern@mweb.co.za" target="_blank" rel="noreferrer">klooffern@mweb.co.za</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default IndustrialAndFood