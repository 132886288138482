import React from 'react'

class NavSocialMedia extends React.Component{
    render(){
        return(
            <ul>
                {/* <li>
                    <a href="https://www.facebook.com/pages/Cecil-Vinegar-Works/309837302682582" target="_blank" rel="noreferrer" className="_socialmedia-icon" aria-label='facebook'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="39.997" height="39.997" viewBox="0 0 39.997 39.997" aria-hidden="true">
                            <path id="facebook_icon" data-name="facebook icon" d="M20,40A19.905,19.905,0,0,1,8.817,36.581a20.056,20.056,0,0,1-7.246-8.8A19.972,19.972,0,0,1,3.415,8.817a20.057,20.057,0,0,1,8.8-7.246A19.972,19.972,0,0,1,31.18,3.415a20.057,20.057,0,0,1,7.245,8.8A19.972,19.972,0,0,1,36.581,31.18a20.056,20.056,0,0,1-8.8,7.245A19.873,19.873,0,0,1,20,40Zm-6.519-23.81v4.594h2.572V33.854h5.406V20.781h3.568l.472-4.59h-4.04v-2.7a1.232,1.232,0,0,1,.395-1.007,1.2,1.2,0,0,1,.752-.246H25.51V7.775l-4-.017a6.275,6.275,0,0,0-2.727.553,4.42,4.42,0,0,0-1.667,1.377,6.065,6.065,0,0,0-1.061,3.525v2.973Z"/>
                        </svg>
                    </a>
                </li> */}
            </ul>
        )
    }
}

export default NavSocialMedia