import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import BrandLogos from './views/BrandLogos'
import RecipesSection from './views/RecipesSection'
import {connect} from 'react-redux'
import {
    fetchPublicPageRecipes
} from '../actions'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/recipes/recipes-banner.jpg'

class Recipes extends React.Component {

    componentDidMount(){
        this.props.fetchPublicPageRecipes()
    }

    render(){

        console.log(this.props.test)

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>Recipes | Cecil Vinegar Works</title>
                    <meta name="description" content="When it comes to cooking, vinegar stands out as a fantastically versatile ingredient with impressive range and potency. Vinegar can perform many useful functions in recipes such as improving flavour, food preservation, substituting in for missing ingredients and improving the appearance of the meal. One thing’s for certain, vinegar is a secret ingredient for just about any meal occasion." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                    <PageHeaderSection 
                        img={banner}
                    >
                        <h1>RECIPES</h1>
                    </PageHeaderSection>
                    <BrandLogos />
                    <RecipesSection data={this.props.recipes} className="_padding-top-none" />
                <PreFooterSection />
            </Layout>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        recipes: Object.values(state.publicRecipesState),
        test: state.publicRecipesState
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageRecipes
})(Recipes)