export const SIGNIN = 'SIGNIN'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'
export const SIGNOUT = 'SIGNOUT'

export const RESET_FORMS = 'RESET_FORMS'

export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS_FAILES = 'UPDATE_ACCOUNT_DETAILS_FAILES'

export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER = 'DELETE_USER'

export const FETCH_BRANDS = 'FETCH_BRANDS'
export const CREATE_BRAND = 'CREATE_BRAND'
export const CREATE_BRAND_FAILED = 'CREATE_BRAND_FAILED'
export const FETCH_BRAND = 'FETCH_BRAND'
export const UPDATE_BRAND = 'UPDATE_BRAND'
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED'
export const DELETE_BRAND = 'DELETE_BRAND'
export const RESTORE_BRAND = 'RESTORE_BRAND'
export const FETCH_BRANDS_BIN = 'FETCH_BRANDS_BIN'
export const FORCE_DELETE_BRAND = 'FORCE_DELETE_BRANDS'

export const FETCH_RANGES = 'FETCH_RANGES'
export const CREATE_RANGE = 'CREATE_RANGE'
export const CREATE_RANGE_FAILED = 'CREATE_RANGE_FAILED'
export const FETCH_RANGE = 'FETCH_RANGE'
export const UPDATE_RANGE = 'UPDATE_RANGE'
export const UPDATE_RANGE_FAILED = 'UPDATE_RANGE_FAILED'
export const DELETE_RANGE = 'DELETE_RANGE'
export const RESTORE_RANGE = 'RESTORE_RANGE'
export const FETCH_RANGES_BIN = 'FETCH_RANGES_BIN'
export const FORCE_DELETE_RANGE = 'FORCE_DELETE_RANGES'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT'
export const FETCH_PRODUCTS_BIN = 'FETCH_PRODUCTS_BIN'
export const FORCE_DELETE_PRODUCT = 'FORCE_DELETE_PRODUCTS'

export const FETCH_RECIPES = 'FETCH_RECIPES'
export const CREATE_RECIPE = 'CREATE_RECIPE'
export const CREATE_RECIPE_FAILED = 'CREATE_RECIPE_FAILED'
export const FETCH_RECIPE = 'FETCH_RECIPE'
export const UPDATE_RECIPE = 'UPDATE_RECIPE'
export const UPDATE_RECIPE_FAILED = 'UPDATE_RECIPE_FAILED'
export const DELETE_RECIPE = 'DELETE_RECIPE'
export const RESTORE_RECIPE = 'RESTORE_RECIPE'
export const FETCH_RECIPES_BIN = 'FETCH_RECIPES_BIN'
export const FORCE_DELETE_RECIPE = 'FORCE_DELETE_RECIPES'

export const FETCH_PUBLIC_PAGE_BRANDS = 'FETCH_PUBLIC_PAGE_BRANDS'
export const FETCH_PUBLIC_PAGE_BRANDS_MENU = 'FETCH_PUBLIC_PAGE_BRANDS_MENU'
export const FETCH_PUBLIC_PAGE_LOGOS = 'FETCH_PUBLIC_PAGE_LOGOS'
export const FETCH_PUBLIC_PAGE_BRANDS_EXPLORE = 'FETCH_PUBLIC_PAGE_BRANDS_EXPLORE'
export const FETCH_PUBLIC_PAGE_BRAND_BANNER = 'FETCH_PUBLIC_PAGE_BRAND_BANNER'
export const FETCH_PUBLIC_PAGE_BRAND_RANGES = 'FETCH_PUBLIC_PAGE_BRAND_RANGES'
export const FETCH_PUBLIC_PAGE_PRODUCT = 'FETCH_PUBLIC_PAGE_PRODUCT'
export const FETCH_PUBLIC_PAGE_RECIPES = 'FETCH_PUBLIC_PAGE_RECIPES'

export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE'
export const SEND_CONTACT_MESSAGE_FAILED = 'SEND_CONTACT_MESSAGE_FAILED'