import React from 'react'
import HeaderSlideOpenMenu from './HeaderSlideOpenMenu'
import NavLinks from './NavLinks'

class Menu extends React.Component{
    render(){
        return(
            <div className={`_menu ${this.props.open ? '_show' : ''}`}>
                <HeaderSlideOpenMenu
                    onMenuToggle={this.props.onMenuToggle}
                />
                <nav className='_nav-slideopen'>
                    <NavLinks />
                </nav>
            </div>
        )
    }
}

export default Menu