import React from 'react'
import SplitColumnSection from '../ui/SplitColumnSection'
import {connect} from 'react-redux'
import {
    fetchPublicPageBrands
} from '../../actions'

class Brands extends React.Component {

    componentDidMount(){
        this.props.fetchPublicPageBrands()
    }
    render(){

        return(
            <SplitColumnSection data={this.props.brands} />
        )
    }
}

const mapStateToProps = state => {
    return {
        brands: Object.values(state.publicBrandsState)
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageBrands
})(Brands)