import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import CollectionSection from './ui/CollectionSection'
import ExploreMoreProducts from './views/ExploreMoreProducts'
import {connect} from 'react-redux'
import {
    fetchPublicPageRanges,
    fetchPublicPageExplore,
    fetchPublicPageBrandBanner
} from '../actions'
import {BASE_PATH} from '../paths'
import Helmet from 'react-helmet'
import {Redirect} from 'react-router-dom'

import placeholder from '../assets/img/placeholders/hero.jpg'

class ProductRange extends React.Component {

    componentDidMount(){
        this.props.fetchPublicPageRanges(this.props.match.params.range)
        this.props.fetchPublicPageExplore(this.props.match.params.range)
        this.props.fetchPublicPageBrandBanner(this.props.match.params.range)
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.range !== prevProps.match.params.range){
            this.props.fetchPublicPageRanges(this.props.match.params.range)
            this.props.fetchPublicPageExplore(this.props.match.params.range)
            this.props.fetchPublicPageBrandBanner(this.props.match.params.range)
        }
    }

    renderCollections = () => {

        return this.props.ranges.map((item, index) => {
            return <CollectionSection data={item} key={index} />
        })
    }

    render(){

        if(this.props.match.params.range === 'the-verjuice-co'){
            return <Redirect to="/products/the-verjuice-co/verjuice" />
        }

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>{this.props.banner ? this.props.banner.name : "Products"} | Cecil Vinegar Works</title>
                    <meta name="description" content={this.props.banner ? this.props.banner.summary.replace(/(<([^>]+)>)/gi, "") : "We have a range of different products ranging from those that are locally produced, to those that are imported, and of course our speciality range for the most discerning of buyers."} />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <PageHeaderSection 
                    img={this.props.banner ? BASE_PATH+this.props.banner.banner_image : placeholder}
                >
                    {this.props.banner && <h1>{this.props.banner.name}</h1>}
                    {this.props.banner && (
                        <div dangerouslySetInnerHTML={{__html: this.props.banner.summary}} />
                    )}
                </PageHeaderSection>
               
                {this.props.ranges && this.renderCollections()}
                {this.props.explore && <ExploreMoreProducts data={this.props.explore} />}
                <PreFooterSection />
            </Layout>
            
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ranges: state.publicRangesState[ownProps.match.params.range],
        explore: state.publicExploreState[ownProps.match.params.range],
        banner: state.publicBannerState[ownProps.match.params.range]
    }
}

export default connect(mapStateToProps, {
    fetchPublicPageRanges,
    fetchPublicPageExplore,
    fetchPublicPageBrandBanner
})(ProductRange)