import React from 'react'

class NavMenuCloseButton extends React.Component{
    render(){
        return(
            <ul>
                <li>
                    <button className='_menu-close-button' onClick={this.props.onClick}>
                        <span />
                        <span />
                    </button>
                </li>
            </ul>
        )
    }
}

export default NavMenuCloseButton