import React from 'react'
import TimelineItem from './TimelineItem'

class Timeline extends React.Component {

    renderItems = () => {
        return this.props.data.map((item, index) => {
            return (
                <TimelineItem data={item} key={index} />
            )
        })
    }

    render(){
        return(
            <section className='_section _section-timeline'>
                {this.renderItems()}
            </section>
        )
    }
}

export default Timeline