import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import ProductionItems from './views/ProductionItems'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/about-vinegar/about-vinegar-banner.jpg'
import safari from '../assets/img/brands/safari-logo-cropped.png'
import cecil from '../assets/img/brands/cecil-logo-cropped-2.png'
// import placeholder from '../assets/img/placeholders/featured-product.jpg'
import body from '../assets/img/about-vinegar/body-icon@2x.png'
import food from '../assets/img/about-vinegar/food-icon@2x.png'
import home from '../assets/img/about-vinegar/home-icon@2x.png'

class AboutVinegar extends React.Component {

    render(){

        const production = [
            {
                title: "SPIRIT VINEGAR",
                description: "Our Spirit vinegar is produced from the finest alcohol distilled from sugar cane. It has an off-neutral taste and is widely used as a natural acidifying ingredient by food and condiment producers. It is also an economical flavour enhancer when used in home cooking.",
                logos: [
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-white-spirit-vinegar/'
                    }
                ],
                grade: "A",
                acidity_range: "5% - 17%",
                kosher_halaal_certificate: "Yes",
                draw_materials: "Rectified Cane Spirit",
                color: "White and Brown"
            },
            {
                title: "GRAPE VINEGAR",
                description: "Grape Vinegar is the ideal basic cooking vinegar. Being an unrefined product, Grape vinegar contains potassium and magnesium as well as various trace elements which are essential for good health.",
                logos: [
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-white-grape-vinegar/'
                    }
                ],
                grade: "A+",
                acidity_range: "5% - 10%",
                kosher_halaal_certificate: "Halaal",
                draw_materials: "Distilled Wine",
                color: "White, Golden Yellow and Brown"
            },
            {
                title: "WINE VINEGAR",
                description: "Wine Vinegar is rich in flavour and aroma. It is widely used in the pickling industry as well as for home cooking by consumers who prefer vinegar with a distinctive wine character.",
                logos: [
                    {
                        path: cecil,
                        slug: 'cecil-vinegar-works/white-wine-vinegar'
                    },
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-organic-red-wine-vinegar/'
                    },
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-organic-white-wine-vinegar/'
                    }
                ],
                grade: "A",
                acidity_range: "6% - 10%",
                kosher_halaal_certificate: "Halaal",
                draw_materials: "Red / White Wine",
                color: "Ruby Red / Golden Yellow"
            },
            {
                title: "APPLE CIDER VINEGAR",
                description: "Apple Cider Vinegar is produced from juice extracted from apples grown in the Elgin district of the Western Cape. It is suitable for use in making salad dressings and is also believed by health-conscious consumers to have therapeutic properties.",
                logos: [
                    {
                        path: cecil,
                        slug: 'cecil-vinegar-works/apple-cider-vinegar'
                    },
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-apple-cider-vinegar-filtered/'
                    }
                ],
                grade: "A+",
                acidity_range: "5% - 6%",
                kosher_halaal_certificate: "Yes",
                draw_materials: "Apple Wine",
                color: "Golden Yellow"
            }
        ]

        const importedRange = [
            {
                title: "Balsamic Vinegar",
                description: "Balsamic Vinegar is the choice of the discerning gourmets. To satisfy demand from local consumers, we bottle Balsamic vinegar imported from Modena, Italy.",
                logos: [
                    {
                        path: cecil,
                        slug: 'cecil-vinegar-works/balsamic-vinegar'
                    }
                ],
                grade: "A",
                acidity_range: "5% - 6%",
                kosher_halaal_certificate: "Certification available on request",
                draw_materials: "Wine and fermented grapes",
                color: "White, Golden Yellow and Brown"
            },
            {
                title: "Malt Vinegar",
                description: "Malt Vinegar is made from alcohol derived from malted barley. It is traditional English Vinegar associated with fish and chip vendors. It is also popular with cottage industry traditionalists for pickling purposes.",
                logos: [
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-white-malt-vinegar/'
                    }
                ],
                grade: "A",
                acidity_range: "5%",
                kosher_halaal_certificate: "Yes",
                draw_materials: "Malted Barley",
                color: "White and Brown"
            },
            {
                title: "Rice Vinegar",
                description: "Rice Vinegar is produced from rice wine and is a milder, sweeter and more delicate alternative to regular vinegar. Enjoyed by the Chinese for thousands of years, rice vinegar compliments all oriental dishes.",
                logos: [
                    {
                        path: safari,
                        link: 'https://safarivinegar.co.za/our-products/safari-rice-vinegar/'
                    }
                ],
                grade: "A",
                acidity_range: "5%",
                kosher_halaal_certificate: "Yes",
                draw_materials: "Rice Wine",
                color: "White"
            }
        ]

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>About Vinegar | Cecil Vinegar Works</title>
                    <meta name="description" content={`Being a product that has been around since 4000 BC isn't always easy. While this enduring lifecycle is certainly proof of the appeal of vinegar, there is plenty of opportunity for misconceptions about vinegar to develop. If it says "Vinegar" then it is "Vinegar".`} />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                <PageHeaderSection 
                    img={banner}
                >
                    <h1>ABOUT VINEGAR</h1>
                </PageHeaderSection>
                <section className='_section _section-about-vinegar'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_section-heading'>The Virtues of Vinegar</h2>
                                <p>Being a product that has been around since 4000 BC isn't always easy. While this enduring lifecycle is certainly proof of the appeal of vinegar, there is plenty of opportunity for misconceptions about vinegar to develop.</p>
                                <p>If it says "Vinegar" then it is "Vinegar".</p>
                                <p>No misconception here! Or at least there shouldn't be. But there is something more you should know.</p>
                                <p>Although South African food laws determine that synthetic acetic acid may not be labelled as spirit vinegar, but rather imitation vinegar, some unethical bottlers will fraudulently substitute diluted synthetic acetic acid and offer it as naturally fermented spirit vinegar.</p>
                                <p>Since our vinegars are naturally fermented, they contain natural trace elements and nutrients derived from the raw materials source which are good for your health.</p>
                                <p>South African Natural Vinegar Manufacture Association (SANVA) was founded to protect consumers and distinguish naturally fermented vinegar from the synthetic alternative. SANVA has established a seal which appears on the labels of most bottles of naturally fermented vinegar.</p>
                            </div>
                        </div>
                    </div>
                </section>
                
                <ProductionItems data={production} heading="Vinegar Production" />
                <ProductionItems data={importedRange} heading="Imported Range" />

                <section className='_section _section-about-vinegar' style={{paddingBottom: '0', paddingTop: '0'}}>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_section-heading'>Other specialty vinegars</h2>
                                <p>Although the ranges above are the most recognized, there are various other specialty vinegars in the world. Some of these that we import and bottle include; Prosecco Vinegar and Sherry Vinegar, and are great to use as a condiment or to use as a salad dressing.</p>
                                <p>There are so many ways that vinegars can be used to improve the quality, flavour and longevity of your food, so visit our recipe section. Please contact us if you require any additional information regarding to grade, acidity ranges, certifications or specifications.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='_section _section-about-vinegar'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h3 style={{marginTop: '0'}}>There are so many ways that vinegars can be used to improve the quality, 
                            flavour and longevity of your food.</h3>
                        </div>
                    </div>
                    <div className='_grid _grid-3'>
                        <div className='_column'>
                            <a href="https://safarivinegar.co.za/how-to-body/" target="_blank" rel="noreferrer">
                                <img src={food} className="_item-logo" alt="Wellness" width="150" height="150" />
                                <h3>Food</h3>
                            </a>
                        </div>
                        <div className='_column'>
                            <a href="https://safarivinegar.co.za/how-to-body/" target="_blank" rel="noreferrer">
                                <img src={home} className="_item-logo" alt="Wellness" width="150" height="150" />
                                <h3>Home</h3>
                            </a>
                        </div>
                        <div className='_column'>
                            <a href="https://safarivinegar.co.za/how-to-body/" target="_blank" rel="noreferrer">
                                <img src={body} className="_item-logo" alt="Wellness" width="150" height="150" />
                                <h3>Body</h3>
                            </a>
                            
                        </div>
                    </div>
                </section>
                
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default AboutVinegar