import {
    FETCH_PUBLIC_PAGE_BRANDS_MENU
} from '../actions/types'

const initialState = []

const publicBrandsMenuReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PAGE_BRANDS_MENU:
            return action.payload
        default:
            return state
    }
}

export default publicBrandsMenuReducer