import {
    FETCH_PUBLIC_PAGE_BRAND_RANGES
} from '../actions/types'

const initialState = {}

const publicRangesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_PAGE_BRAND_RANGES:
            return {
                ...state, [action.brandSlug]: action.payload
            }
        default:
            return state
    }
}

export default publicRangesReducer