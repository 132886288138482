import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import {Link} from 'react-router-dom'
import Helmet from 'react-helmet'

class ContactThankyou extends React.Component {

    render(){

        return(
            <Layout>
                <Helmet>
                    <title>Thank You | Contact Us | Cecil Vinegar Works</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <ScrollToTop />
                <section className='_section _section-page-heading-normal'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_section-heading'>THANK YOU!</h1>
                            <p>Thank you for contacting Cecil Vinegar Works. A consultant will be in touch with you shortly</p>
                            <Link to="/" className="_button _button-2" style={{marginBottom: '100px', marginTop: '20px'}}>Go To Home Page</Link>
                        </div>
                    </div>
                </section>
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default ContactThankyou