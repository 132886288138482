import React from 'react'

import nfl from '../../assets/img/nfv-logo@2x.png'

class PreFooterSection extends React.Component {
    render(){
        return(
            <section className='_section _section-pre-footer'>
                <div className='_grid _grid-2'>
                    <div className='_column'>
                        <h2 className='_section-heading'>Quality & Food Safety</h2>
                        <div className='_copy'>
                            <p>Cecil Vinegar Works strives for the highest quality and food safety standards with an ethos of continuous improvement. </p>
                            <p>Our vinegar manufacturing plants in Strand and Alrode have passed numerous third-party audits, and both are FSSC accredited.</p>
                        </div>
                    </div>
                    <div className='_column'>
                        <h2 className='_section-heading'>SANVA</h2>
                        <div className='_copy'>
                            <p>Cecil Vinegar Works, in conjunction with other South African vinegar manufacturers, formed the South African Natural Vinegar Manufacturer’s Association (SANVA) to protect the interests of its members and to promote the use of naturally fermented vinegar in food. </p>
                            <p>In order to help consumers distinguish between natural vinegar and chemically produced acetic acid, SANVA requires a natural vinegar seal to be displayed on the label of all vinegars which conform to the specifications laid down by the association.</p>
                            <p>The seal is available free of charge to all manufacturers of products which contain Naturally Fermented Vinegar.</p>
                        </div>
                       
                        
                    </div>
                    <div className='_column'>
                        <h2 className='_section-heading'>Halaal and Kosher</h2>
                        <p>Our vinegar is Halaal and Kosher certified.</p>
                    </div>
                    <div className='_column'>
                        <img src={nfl} alt="nfl" width="170" height="170" />
                    </div>
                </div>
            </section>
        )
    }
}

export default PreFooterSection