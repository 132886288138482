import React from 'react'
import {Link} from 'react-router-dom'

class ProductionItems extends React.Component {

    renderLogos = logos => {
        return logos.map(({path,slug,link}, index) => {
            if(link){
                return (
                    <a href={link} target="_blank" className='_item-logo' rel="noreferrer" key={index}>
                        <img className='_item-logo' src={path} alt="" />
                    </a>
                )
            }
            return (
                <Link to={`/products/${slug ? slug : ''}`} key={index}>
                    <img className='_item-logo' src={path} alt="" />
                </Link>
            )
        })
        
    }

    renderItems = () => {
        return this.props.data.map(({title,description,logos,grade,acidity_range,kosher_halaal_certificate,draw_materials,color}, index) => {
            return (
                <div className="_production-item" key={index}>
                    <div className='_grid _grid-2'>
                        <div className='_column _left'>
                            <h3 className='_section-heading'>{title}</h3>
                            <p>{description}</p>
                            <div className='_logos'>
                                {this.renderLogos(logos)}
                            </div>
                            
                        </div>
                        <div className='_column _right'>
                            <div className='_panel'>
                                <div className='_panel-item'>
                                    <h5>GRADE</h5>
                                    <p><strong>{grade}</strong></p>
                                </div>
                                <div className='_panel-item'>
                                    <h5>ACIDITY RANGE</h5>
                                    <p><strong>{acidity_range}</strong></p>
                                </div>
                                <div className='_panel-item'>
                                    <h5>KOSHER/HALAAL CERTIFICATE</h5>
                                    <p><strong>{kosher_halaal_certificate}</strong></p>
                                </div>
                            </div>
                            <div className='_panel'>
                                <div className='_panel-item'>
                                    <h5>RAW MATERIALS</h5>
                                    <p><strong>{draw_materials}</strong></p>
                                </div>
                                <div className='_panel-item'>
                                    <h5>COLOUR</h5>
                                    <p><strong>{color}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render(){

        return(
            <>
                {
                    this.props.heading && (
                        <section className="_section _section-vinegar-productions-heading">
                            <div className='_grid'>
                                <div className='_column'>
                                    <h2 className='_section-heading'>{this.props.heading}</h2>
                                </div>
                            </div>
                        </section>
                    )
                }
                
                <section className='_section _section-vinegar-production'>
                    {this.renderItems()}
                </section>
            </>
        )
    }
}

export default ProductionItems