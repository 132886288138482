import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import PreFooterSection from './views/PreFooterSection'
import PageHeaderSection from './ui/PageHeaderSection'
import Brands from './views/Brands'
import Helmet from 'react-helmet'

// images
import banner from '../assets/img/retail/retail-banner.jpg'
import game from '../assets/img/retail/game-logo@2x.png'
import shoprite from '../assets/img/retail/shoprite-logo@2x.png'
import usave from '../assets/img/retail/usave-logo@2x.png'
import pnp from '../assets/img/retail/pnp-logo@2x.png'
import checkers from '../assets/img/retail/checkers-logo@2x.png'
import spar from '../assets/img/retail/spar-logo@2x.png'
import ww from '../assets/img/retail/woolworths-logo@2x.png'

class Retail extends React.Component {

    render(){

        return(
            <Layout styleType="transparent">
                <Helmet>
                    <title>Retail | Cecil Vinegar Works</title>
                    <meta name="description" content="We are proud that our Vinegar is used in many Private Label and Dealer Own Brands across many retailers. Some of the finest locally produced Apple Cider and Wine Vinegars complemented with quality Balsamic Vinegar imported from Modena, Italy are sold under our very own “Cecil Vinegar” brand available in both glass and PET formats." />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                <ScrollToTop />
                    <PageHeaderSection 
                        img={banner}
                    >
                        <h1>RETAIL</h1>
                    </PageHeaderSection>
                    <section className='_section _section-brand-logos _section-retail-logos'>
                        <div className='_grid'>
                            <div className='_column' style={{width: '100%'}}>
                                <h2 className='_section-heading'>FIND OUR BRANDS AT</h2>
                            </div>
                        </div>
                        <div className='_grid _grid-4'>
                            <div className='_column'>
                                <img src={checkers} alt="" className='_brand-checkers' />
                            </div>
                            <div className='_column'>
                                <img src={pnp} alt="" className='_brand-pnp' />
                            </div>
                            <div className='_column'>
                                <img src={spar} alt="" className='_brand-spar' />
                            </div>
                            <div className='_column'>
                                <img src={ww} alt="" className='_brand-ww' />
                            </div>
                            <div className='_column'>
                                <img src={usave} alt="" className='_brand-usave' />
                            </div>
                            <div className='_column'>
                                <img src={shoprite} alt="" className='_brand-shoprite' />
                            </div>
                            <div className='_column'>
                                <img src={game} alt="" className='_brand-game' />
                            </div>

                            


                        </div>
                        <div className='_grid'>
                            <div className='_column' style={{width: '100%'}}>
                                <p style={{margin: '30px 0 0 0'}}>We are proud that our Vinegar is used in many Private Label and Dealer Own Brands across many retailers</p>
                            </div>
                        </div>
                    </section>
                    <Brands />
                <PreFooterSection />
            </Layout>
            
        )
    }
}

export default Retail