import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

//auth
import authReducer from './authReducer'
import myAccountReducer from './myAccountReducer'

//users
import usersReducer from './usersReducer'
import createUserReducer from './createUserReducer'
import updateUserReducer from './updateUserReducer'

//brands
import brandsReducer from './brandsReducer'
import createBrandReducer from './createBrandReducer'
import updateBrandReducer from './updateBrandReducer'
import brandsBinReducer from './brandsBinReducer'

//ranges
import rangesReducer from './rangesReducer'
import createRangeReducer from './createRangeReducer'
import updateRangeReducer from './updateRangeReducer'
import rangesBinReducer from './rangesBinReducer'

//products
import productsReducer from './productsReducer'
import createProductReducer from './createProductReducer'
import updateProductReducer from './updateProductReducer'
import productsBinReducer from './productsBinReducer'

//recipes
import recipesReducer from './recipesReducer'
import createRecipeReducer from './createRecipeReducer'
import updateRecipeReducer from './updateRecipeReducer'
import recipesBinReducer from './recipesBinReducer'

//public
import publicBrandsReducer from './publicBrandsReducer'
import publicBrandsMenuReducer from './publicBrandsMenuReducer'
import publicLogosReducer from './publicLogosReducer'
import publicBannerReducer from './publicBannerReducer'
import publicExploreReducer from './publicExploreReducer'
import publicRangesReducer from './publicRangesReducer'
import publicProductReducer from './publicProductReducer'
import publicRecipesReducer from './publicRecipesReducer'

import contactReducer from './contactReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authState']
}

const rootReducer = combineReducers({
    authState: authReducer,
    myAccountState: myAccountReducer,
    usersState: usersReducer,
    createUserState: createUserReducer,
    updateUserState: updateUserReducer,
    brandsState: brandsReducer,
    createBrandstate: createBrandReducer,
    updateBrandstate: updateBrandReducer,
    brandsBinState: brandsBinReducer,
    rangesState: rangesReducer,
    createRangestate: createRangeReducer,
    updateRangestate: updateRangeReducer,
    rangesBinState: rangesBinReducer,
    productsState: productsReducer,
    createProductState: createProductReducer,
    updateProductState: updateProductReducer,
    productsBinState: productsBinReducer,
    recipesState: recipesReducer,
    createRecipestate: createRecipeReducer,
    updateRecipestate: updateRecipeReducer,
    recipesBinState: recipesBinReducer,
    publicBrandsState: publicBrandsReducer,
    publicBrandsMenuState: publicBrandsMenuReducer,
    publicLogosState: publicLogosReducer,
    publicExploreState: publicExploreReducer,
    publicBannerState: publicBannerReducer,
    publicRangesState: publicRangesReducer,
    publicProductState: publicProductReducer,
    publicRecipesState: publicRecipesReducer,
    contactSatate: contactReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)