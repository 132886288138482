import React from 'react'
import NavSocialMedia from './NavSocialMedia'
import NavRight from './NavRight'
import NavLeft from './NavLeft'
import NavLogo from './NavLogo'
import NavMenuCloseButton from './NavMenuCloseButton'

class HeaderSlideOpenMenu extends React.Component{
    render(){
        return(
            <header className={`_header _header-slideopen _show`}>
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_header-items'>
                            <div className='_header-item'>
                                <NavSocialMedia />
                            </div>
                            <div className='_header-item'>
                                <NavLeft />
                            </div>
                            <div className='_header-item'>
                                <NavLogo />
                            </div>
                            <div className='_header-item'>
                                <NavRight />
                            </div>
                            <div className='_header-item'>
                                <NavMenuCloseButton onClick={this.props.onMenuToggle} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderSlideOpenMenu