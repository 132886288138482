import React from 'react'
import { Parallax } from 'react-parallax';

class ParallaxImageSection extends React.Component {
    render(){

        const {img, alt} = this.props

        return(
            <Parallax  bgImage={img} bgImageAlt={alt} strength={200}>
                <section className='_section _section-bgimage-side-content'>
                    <div className='_grid _grid-2'>
                    {!this.props.left && <div className='_column' />}
                    <div className='_column'>
                        <div className='_panel'>
                           {this.props.children}
                        </div>
                    </div>
                    {this.props.left && <div className='_column' />}
                    </div>
                </section>
            </Parallax>
            
        )
    }
}

export default ParallaxImageSection